import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { Container, Navbar, NavDropdown, Dropdown, Nav } from 'react-bootstrap';
import { loadCurrentUser, logout } from '@features/auth/reducer';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@assets/fonts/fontello-9c8de8b5/css/fontello.css'
import '@styles/form.scss';
import '@styles/icon.scss';
import './Layout.scss';

class Layout extends PureComponent {

  componentDidMount() {
    const { loadCurrentUser } = this.props;
    loadCurrentUser();
  }

  onLogout() {
    const { logout } = this.props;
    logout().then(() => {
      window.location.href = '/';
    });
  }

  render() {
    const { children, currentUser, loadCurrentUser, logout, hideFooter, hideMainTopbar, mainTopbar, additionRightMenu, ...props } = this.props;
    
    return (
      <div id="wrapper" {...props}>
        <Navbar id="navbar" expand="lg" fixed="top">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Link to="/" className="navbar-brand">
              <div className="logo" alt="Young Thon" />
            </Link>

            { !hideMainTopbar && (
              <ul className="navbar-nav navbar-main">
                <li><Link to="/" className="nav-link">หน้าแรก</Link></li>
                <li><Link to="/about" className="nav-link">รู้จักเรา</Link></li>
                <li><Link to="/actors" className="nav-link">รวมคน</Link></li>
                <li><Link to="/projects" className="nav-link">รวมงาน</Link></li>
              </ul>
            )}

            { mainTopbar }

            <ul className="navbar-nav navbar-right">
              { additionRightMenu && additionRightMenu.map(element => (
                <li>{element}</li>
              ))}
              { currentUser? (
                <NavDropdown title={<i className="icon icon-user-circle"></i>} alignRight>
                  <Link to="/profile/actors" className="dropdown-item">จัดการคน</Link>
                  <Dropdown.Divider />
                  {/* <Link to="/profile/settings" className="dropdown-item">แก้ไขข้อมูลส่วนตัว</Link>
                  <Link to="/profile/change-password" className="dropdown-item">เปลี่ยนรหัสผ่าน</Link> */}
                  <div className="dropdown-item" onClick={() => this.onLogout()}>Log out</div>
                </NavDropdown>
              ): (
                <Fragment>
                  <li><Link to="/auth/login" className="nav-link">เข้าสู่ระบบ / สมัครสมาชิก</Link></li>
                </Fragment>
              )}
            </ul>
          </Navbar.Collapse>
        </Navbar>
        <div id="main">
          {children}
        </div>
        { !hideFooter && (
          <div id="footer">
            <Container>
              <div className="logo"></div>
              <p>ศึกษาวิจัยโดย กลุ่มยังธน ร่วมกับ มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี สนับสนุนโดย กองทุนพัฒนาสื่อปลอดภัยและสร้างสรรค์ เนื้อหาทั้งหมดสงวนสิทธิ์ภายใต้สัญญาอนุญาต Creative Commons อ้างอิงแหล่งที่มา ห้ามดัดแปลงภาพหรือข้อมูล สัญญาอนุญาตสากล 4.0 เว้นแต่มีการระบุไว้เป็นอย่างอื่น | ออกแบบและพัฒนาโดย มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี</p>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCurrentUser,
  logout,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Layout);