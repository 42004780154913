import * as base from './_base';
import { endpoint } from '@config/api';

export default {
  register: (data, options) => {
    const url = `${endpoint}/auth/register`;
    return base.post(url, data, options);
  },
  login: (data, options) => {
    const url = `${endpoint}/auth/login`;
    return base.post(url, data, options);
  },
  logout: (data, options) => {
    const url = `${endpoint}/auth/logout`;
    return base.post(url, data, options);
  }
}