import api from '@app/api';

export const FIND_PROJECTS_REQUEST  = 'action.projects.find.request';
export const FIND_PROJECTS_SUCCESS  = 'action.projects.find.success';
export const FIND_PROJECTS_ERROR    = 'action.projects.find.error';

export const GET_PROJECT_REQUEST    = 'action.projects.get.request';
export const GET_PROJECT_SUCCESS    = 'action.projects.get.success';
export const GET_PROJECT_ERROR      = 'action.projects.get.error';

export const COUNT_PROJECT_REQUEST  = 'action.projects.count.request';
export const COUNT_PROJECT_SUCCESS  = 'action.projects.count.success';
export const COUNT_PROJECT_ERROR    = 'action.projects.count.error';

export const CREATE_PROJECT_REQUEST = 'action.projects.create.request';
export const CREATE_PROJECT_SUCCESS = 'action.projects.create.success';
export const CREATE_PROJECT_ERROR   = 'action.projects.create.error';

export const UPDATE_PROJECT_REQUEST = 'action.projects.update.request';
export const UPDATE_PROJECT_SUCCESS = 'action.projects.update.success';
export const UPDATE_PROJECT_ERROR   = 'action.projects.update.error';

export const DELETE_PROJECT_REQUEST = 'action.projects.delete.request';
export const DELETE_PROJECT_SUCCESS = 'action.projects.delete.success';
export const DELETE_PROJECT_ERROR   = 'action.projects.delete.error';

export const FIND_PROJECT_ACTORS_REQUEST = 'action.projects.find.actors.request';
export const FIND_PROJECT_ACTORS_SUCCESS = 'action.projects.find.actors.success';
export const FIND_PROJECT_ACTORS_ERROR   = 'action.projects.find.actors.error';

export const ADD_PROJECT_ACTOR_REQUEST = 'action.projects.add.actor.request';
export const ADD_PROJECT_ACTOR_SUCCESS = 'action.projects.add.actor.success';
export const ADD_PROJECT_ACTOR_ERROR   = 'action.projects.add.actor.error';

export const REMOVE_PROJECT_ACTOR_REQUEST = 'action.projects.remove.actor.request';
export const REMOVE_PROJECT_ACTOR_SUCCESS = 'action.projects.remove.actor.success';
export const REMOVE_PROJECT_ACTOR_ERROR   = 'action.projects.remove.actor.error';

export const FIND_PROJECT_ARTICLES_REQUEST = 'action.projects.find.articles.request';
export const FIND_PROJECT_ARTICLES_SUCCESS = 'action.projects.find.articles.success';
export const FIND_PROJECT_ARTICLES_ERROR   = 'action.projects.find.articles.error';

export const FIND_PROJECT_NEEDS_REQUEST = 'action.projects.find.needs.request';
export const FIND_PROJECT_NEEDS_SUCCESS = 'action.projects.find.needs.success';
export const FIND_PROJECT_NEEDS_ERROR   = 'action.projects.find.needs.error';

export const FIND_PROJECT_IMAGES_REQUEST = 'action.projects.find.images.request';
export const FIND_PROJECT_IMAGES_SUCCESS = 'action.projects.find.images.success';
export const FIND_PROJECT_IMAGES_ERROR   = 'action.projects.find.images.error';

export const ADD_PROJECT_IMAGES_REQUEST = 'action.projects.add.images.request';
export const ADD_PROJECT_IMAGES_SUCCESS = 'action.projects.add.images.success';
export const ADD_PROJECT_IMAGES_ERROR   = 'action.projects.add.images.error';

export const REMOVE_PROJECT_IMAGES_REQUEST = 'action.projects.remove.images.request';
export const REMOVE_PROJECT_IMAGES_SUCCESS = 'action.projects.remove.images.success';
export const REMOVE_PROJECT_IMAGES_ERROR   = 'action.projects.remove.images.error';


const initialState = {
  list: {
    projects: [],
    loading: false,
    error: null,
  },
  new: {
    loading: false,
    error: null,
  },
  edit: {
    project: null,
    loading: false,
    error: null,
  }
};

export const issues = [
  {
    value: 'สังคม',
    text: 'สังคม',
    iconClass: 'icon-run',
  },
  {
    value: 'สิ่งแวดล้อม',
    text: 'สิ่งแวดล้อม',
    iconClass: 'icon-flower',
  },
  {
    value: 'ศิลปวัฒนธรรม',
    text: 'ศิลปวัฒนธรรม',
    iconClass: 'icon-music',
  },
  {
    value: 'อาหาร',
    text: 'อาหาร',
    iconClass: 'icon-food',
  },
  {
    value: 'การเรียนรู้',
    text: 'การเรียนรู้',
    iconClass: 'icon-education',
  },
  {
    value: 'สุขภาวะ',
    text: 'สุขภาวะ',
    iconClass: 'icon-heart',
  },
  {
    value: 'เศรษฐกิจ',
    text: 'เศรษฐกิจ',
    iconClass: 'icon-cart',
  },
  {
    value: 'การท่องเที่ยว',
    text: 'การท่องเที่ยว',
    iconClass: 'icon-camera',
  },
  {
    value: 'ที่อยู่อาศัย',
    text: 'ที่อยู่อาศัย',
    iconClass: 'icon-home',
  },
  {
    value: 'อื่นๆ',
    text: 'อื่นๆ',
    iconClass: 'icon-triangle',
  },  
];


// Reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case FIND_PROJECTS_REQUEST:
      return { ...state, list: { ...state.list,
        loading: true,
        error: null,
      }};

    case FIND_PROJECTS_SUCCESS:
      return { ...state, list: { ...state.list,
        projects: action.projects,
        loading: false,
        error: null,
      }};

    case FIND_PROJECTS_ERROR:
      return { ...state, list: { ...state.list,
        projects: [],
        loading: false,
        error: action.error,
      }};

    case GET_PROJECT_REQUEST:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          project: null,
          loading: true,
          error: null,
        }};
      }
      break;

    case GET_PROJECT_SUCCESS:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          project: action.project,
          loading: false,
          error: null,
        }};
      }
      break;

    case GET_PROJECT_ERROR:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          project: null,
          loading: false,
          error: action.error,
        }};
      }
      break;
              
    default: break;
  }
  return state;
}

// Actions
export const findProjects = (params = {}, options = {}) => dispatch => {
  let query = {
    page: 1,
    limit: 20,
  };
  if (params.issues && !params.issues.all) {
    if (Object.keys(params.issues).filter(key => params.issues[key]).length === 0) {
      query['issues'] = 'none';
    }
    else {
      query['issues'] = Object.keys(params.issues).filter(key => (params.issues[key] && key !== 'all')).join(',');
    }
  }

  dispatch({ type: FIND_PROJECTS_REQUEST, query });
  return api.projects.find(query, options).then(res => {
    const { projects } = res.data;
    dispatch({ type: FIND_PROJECTS_SUCCESS, projects });
    return projects;
  }).catch(error => dispatch({ type: FIND_PROJECTS_ERROR, error }));
}

export const countProjects = () => dispatch => {
  console.log('countProjects: init');
  dispatch({ type: COUNT_PROJECT_REQUEST });
  return api.projects.count().then(res => {
    const { count } = res.data;
    dispatch({ type: COUNT_PROJECT_SUCCESS, count });
    return count;
  })
}


export const createProject = (data, options = {}) => dispatch => {
  dispatch({ type: CREATE_PROJECT_REQUEST, data });
  return api.projects.create(data, options).then(res => {
    const { project } = res.data;
    dispatch({ type: CREATE_PROJECT_SUCCESS, project });
    return project;
  }).catch(error => dispatch({ type: CREATE_PROJECT_ERROR, error }));
}

export const getProject = (id, options = {}) => dispatch => {
  const { mode = 'edit' } = options;
  dispatch({ type: GET_PROJECT_REQUEST, id, mode });
  return api.projects.get(id, options).then(res => {
    const { project } = res.data;
    dispatch({ type: GET_PROJECT_SUCCESS, project, mode });
    return project;
  }).catch(error => dispatch({ type: GET_PROJECT_ERROR, error, mode }));
}

export const updateProject = (id, data, options = {}) => dispatch => {
  console.log('data: ', data);
  dispatch({ type: UPDATE_PROJECT_REQUEST, id, data });
  return api.projects.update(id, data, options).then(res => {
    dispatch({ type: UPDATE_PROJECT_SUCCESS });
  }).catch(error => dispatch({ type: UPDATE_PROJECT_ERROR, error }));
}

export const deleteProject = (id, options = {}) => dispatch => {
  dispatch({ type: DELETE_PROJECT_REQUEST, id });
  return api.projects.del(id).then(res => {
    dispatch({ type: DELETE_PROJECT_SUCCESS });
  }).catch(error => {
    dispatch({ type: DELETE_PROJECT_ERROR, error })
  });
}

//###################### Project Actors #######################

export const findProjectActors = (projectId, params = {}, options = {}) => dispatch => {
  const query = { page: 1, ...params };
  dispatch({ type: FIND_PROJECT_ACTORS_REQUEST, projectId, query });
  return api.projects.findActors(projectId, query, options).then(res => {
    const { actors } = res.data;
    dispatch({ type: FIND_PROJECT_ACTORS_SUCCESS, actors });
    return actors;
  }).catch(error => dispatch({ type: FIND_PROJECT_ACTORS_ERROR, error }));
}

export const addProjectActor = (projectId, data, options = {}) => dispatch => {
  dispatch({ type: ADD_PROJECT_ACTOR_REQUEST, projectId, data });
  return api.projects.addActor(projectId, data, options).then(res => {
    const { actor, member } = res.data;
    dispatch({ type: ADD_PROJECT_ACTOR_SUCCESS, actor, member });
    return { actor };
  }).catch(error => dispatch({ type: ADD_PROJECT_ACTOR_ERROR, error }));
}

export const removeProjectActor = (projectId, actorId, options = {}) => dispatch => {
  dispatch({ type: REMOVE_PROJECT_ACTOR_REQUEST, projectId, actorId });
  return api.projects.removeActor(projectId, actorId, options).then(res => {
    dispatch({ type: REMOVE_PROJECT_ACTOR_SUCCESS });
  }).catch(error => dispatch({ type: REMOVE_PROJECT_ACTOR_ERROR, error }));
}

//###################### Project Articles #######################

export const findProjectArticles = (id, params = {}, options = {}) => dispatch => {
  const query = { page: 1, limit: 20, ...params };
  dispatch({ type: FIND_PROJECT_ARTICLES_REQUEST, id, query });
  return api.projects.findArticles(id, query, options).then(res => {
    const { articles } = res.data;
    dispatch({ type: FIND_PROJECT_ARTICLES_SUCCESS, articles });
    return articles;
  }).catch(error => dispatch({ type: FIND_PROJECT_ARTICLES_ERROR, error }));
}

//###################### Project Needs #######################

export const findProjectNeeds = (id, params = {}, options = {}) => dispatch => {
  const query = { page: 1, limit: 20, ...params };
  dispatch({ type: FIND_PROJECT_NEEDS_REQUEST, id, query });
  return api.projects.findNeeds(id, query, options).then(res => {
    const { needs } = res.data;
    dispatch({ type: FIND_PROJECT_NEEDS_SUCCESS, needs });
    return needs;
  }).catch(error => dispatch({ type: FIND_PROJECT_NEEDS_ERROR, error }));
}

//###################### Project Images #######################

export const findProjectImages = (projectId, params = {}, options = {}) => dispatch => {
  const query = { page: 1, ...params };
  dispatch({ type: FIND_PROJECT_IMAGES_REQUEST, projectId, query });
  return api.projects.findImages(projectId, query, options).then(res => {
    const { images } = res.data;
    dispatch({ type: FIND_PROJECT_IMAGES_SUCCESS, images });
    return images;
  }).catch(error => dispatch({ type: FIND_PROJECT_IMAGES_ERROR, error }));
}

export const addProjectImages = (projectId, data, options = {}) => dispatch => {
  dispatch({ type: ADD_PROJECT_IMAGES_REQUEST, projectId, data });
  return api.projects.addImages(projectId, data, options).then(res => {
    const { images } = res.data;
    dispatch({ type: ADD_PROJECT_IMAGES_SUCCESS, images });
    return { images };
  }).catch(error => dispatch({ type: ADD_PROJECT_IMAGES_ERROR, error }));
}

export const removeProjectImage = (projectId, imageId, options = {}) => dispatch => {
  dispatch({ type: REMOVE_PROJECT_IMAGES_REQUEST, projectId, imageId });
  return api.projects.removeImage(projectId, imageId, options).then(res => {
    dispatch({ type: REMOVE_PROJECT_IMAGES_SUCCESS });
  }).catch(error => dispatch({ type: REMOVE_PROJECT_IMAGES_ERROR, error }));
}
