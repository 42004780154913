export const colors = {
  blue: '#345E9B',
  terquoise: '#1A87A0',
  green: '#488444',
  yellow: '#FEAF12',
  orange: '#FC823E',
  purple: '#8D469E',
  red: '#D13B40',
  grey: '#808080',
  darkGrey: '#484848',
}