import { generateCRUD } from './_base';
import auth from './auth';
import account from './account';
import files from './files';
import images from './images';
import actors from './actors';
import projects from './projects';

export default {
  auth,
  account,
  actors,
  projects,
  tags: generateCRUD('tags'),
  articles: generateCRUD('articles'),
  needs: generateCRUD('needs'),
  files,
  images,
};