import React, { PureComponent } from 'react';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';

class InputField extends PureComponent {
  render() {
    const { input, label, type, required, className, meta: { touched, error, warning}, children } = this.props;
    return (
      <Form.Group className={classnames(className, {
          required: required,
        })}>

        <Form.Label>
          {label}
        </Form.Label>

        { type === 'textarea'? (
          <textarea className="form-control" {...input} />
        ) : type === 'select'? (
          <select className="form-control" {...input}>
            {children}
          </select>
        ) : (
          <input
            className="form-control"
            {...input}
          />
        )}
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </Form.Group>
    )
  }
}

export default InputField;