import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Container } from 'react-bootstrap';
import ProjectForm from '../components/ProjectForm';
import { createProject } from '../reducer';

class ProjectNewPage extends PureComponent {

  onSubmit(values) {
    const { createProject, history } = this.props;
    createProject({
      ...values,
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas: values.areas && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(() => {
      history.push('/projects');
    });
  }

  render() {
    return (
      <div>
        <Container>
          <section className="page-header">
            <h1>New Project</h1>
          </section>
          <section className="page-body">
            <ProjectForm onSubmit={values => this.onSubmit(values)} />
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  createProject,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNewPage);