import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActorProfileLayout from '../containers/ActorProfileLayout';
import { getActor } from '../reducer';

class ActorProfileNeedListPage extends PureComponent {

  state = {
    actor: null,
    needs: null,
  }

  async componentDidMount() {
    const { id, getActor } = this.props;
    const actor = await getActor(id);
    
    this.setState({ actor })
  }

  render() {
    const { actor } = this.state;
    return (
      <Fragment>
        {actor && (
          <ActorProfileLayout actor={actor}>
            <h3>Needs</h3>

          </ActorProfileLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorProfileNeedListPage);