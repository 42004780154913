import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';

import { findProjects, deleteProject } from '../reducer';

class ProjectListPage extends PureComponent {
  componentDidMount() {
    const { findProjects } = this.props;
    findProjects();
  }

  onDeleteProject(id) {
    const { deleteProject, findProjects } = this.props;
    deleteProject(id).then(() => {
      findProjects();
    });
  }

  render() {
    const { projects } = this.props;
    return (
      <div>
        <Container>
          <section className="page-header">
            <h1 className="page-title">Project</h1>
            <ButtonToolbar className="justify-content-between">
              <ButtonGroup>
                <Link to="/projects/new"><Button variant="primary">New</Button></Link>
              </ButtonGroup>
              <ButtonGroup>
              </ButtonGroup>
            </ButtonToolbar>
          </section>
          <section className="page-body">
            <div className="project-list">
              {projects && projects.map(project => (
                <Row key={project.id}>
                  <Col><Link to={`/projects/${project.id}/settings`}>{project.name}</Link></Col>
                  <Col>
                    <ButtonGroup>
                      <Button variant="danger" onClick={e => this.onDeleteProject(project.id)}>Delete</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              ))}
            </div>
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.project.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  findProjects,
  deleteProject,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListPage);