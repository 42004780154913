import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { reduxForm, change, Field, FieldArray } from 'redux-form';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import moment from 'moment-with-locales-es6';
import Layout from '@features/home/components/Layout';
import DraftView from '@components/DraftView';
import { Map, isValidCoordinate, ActorMarker, ProjectMarker, Popup, Layer, Feature } from '@components/MapBox';
import ActorCard from '@features/actors/components/ActorCard';
import ProjectCard from '@features/projects/components/ProjectCard';
import ProjectSlider from '@features/projects/components/ProjectSlider';

// Actions
import { getActor, findActorProjects, ActorTypes, findActorImages, addActorImages, removeActorImage } from '@features/actors/reducer';
import { findTags } from '@features/tags/reducer';
import { uploadImages } from '@features/main/reducer';

// Form
import defaultThumbnail from '@assets/images/defaults/thumbnail.png';
import addThumbnail from '@assets/images/add-thumbnail.png';

import DropzoneField from '@components/Field/DropzoneField';
import TagsFieldArray from '@components/Field/TagsFieldArray';
import { DraftField, LatLngField } from '@components/Field';
import UploadImageForm from '@components/Form/UploadImageForm';

moment.locale('th');

class ActorEditForm extends PureComponent {
  state = {
    actor: null,
    projects: [],
    images: [],
    allSkills: [],
    allIssues: [],
    projectViewMode: 'list',
  }

  async componentDidMount() {
    const { getActor, findActorProjects, findActorImages, findTags, id } = this.props;
    const actor = await getActor(id);
    const projects = await findActorProjects(id);
    const images = await findActorImages(id);
    const allSkills = await findTags({ type: 'skill' });
    const allIssues = await findTags({ type: 'issue' });


    this.setState({ actor, projects, images, allSkills, allIssues });
  }

  setProjectViewMode(projectViewMode) {
    this.setState({ projectViewMode });
  }

  onUploadThumbnail = async (data) => {
    const { uploadImages, change } = this.props;
    const { actor } = this.state;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      const thumbnail = images[0].url;
      change('thumbnail', thumbnail);
      this.setState({
        actor: {
          ...actor,
          thumbnail,
        }
       });
    }
  }

  onUploadLogo = async (data) => {
    const { uploadImages, change } = this.props;
    const { actor } = this.state;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      const logo = images[0].url;
      change('logo', logo);
      this.setState({
        actor: {
          ...actor,
          logo,
        }
       });
      
    }
  }

  onUploadGallery = async (data) => {
    const { id, uploadImages, findActorImages, addActorImages } = this.props;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      await addActorImages(id, { imageIds: images.map(image => image.id) });
      this.setState({ images: await findActorImages(id) });
    }
  }

  onRemoveGallery = async (imageId) => {
    const { id, removeActorImage, findActorImages } = this.props;
    await removeActorImage(id, imageId);
    this.setState({ images: await findActorImages(id) });
  }


  render() {
    const { handleSubmit } = this.props;
    const { actor, projects, images, allSkills, allIssues, projectViewMode } = this.state;
    if (!actor) return null;

    const color = ActorTypes[actor.type] && ActorTypes[actor.type].color;
    
    const saveButton = <Button className="yt-btn" onClick={handleSubmit}>บันทึก</Button>

    return actor && (
      <Layout className="actor-page view" additionRightMenu={[saveButton]}>
        <form onSubmit={handleSubmit} className="actor-edit-form">
          <div className="cover-section">
            <div className="cover-wrapper">
              <label>รูป ​Cover</label>
              <div><small>คลิกเพื่อเลือกไฟล์รูปบนเครื่อง หรือ ลากไฟล์รูปภาพมาวาง (รูปควรมีขนาด 800px x 600px ขึ้นไป และไฟล์มีขนาดไม่เกิน 1MB)</small></div>
              <UploadImageForm name="thumbnail" onSubmit={this.onUploadThumbnail}>
                <img src={actor.thumbnail || defaultThumbnail} width="100%" alt="" />
              </UploadImageForm>
            </div>
            <div className="actor-intro">
              <div className="actor-header">
                <label>โลโก้</label>
                <div><small>คลิกเพื่อเลือกไฟล์รูปบนเครื่อง หรือ ลากไฟล์รูปภาพมาวาง (รูปควรมีขนาด 150px x 150px ขึ้นไป และไฟล์มีขนาดไม่เกิน 1MB)</small></div>
                <UploadImageForm className="actor-logo-form" name="logo" onSubmit={this.onUploadLogo}>
                  <img className="actor-logo" src={actor.logo || defaultThumbnail} alt={actor.name} />
                </UploadImageForm>

                <div>
                  <label>ชื่อองค์กร</label>
                  <Field name="name" component="input" className="form-control" type="text" placeholder="ชื่อองค์กร/บุคคล" />
                </div>
              </div>
              <div className="about">
                <label className="font-header"><strong>แนะนำองค์กร</strong></label>
                <Field name="description" component="textarea" className="form-control" />
              </div>
            </div>
          </div>

          
          <Container className="project-list-section">
            <div className="section-header">
              <h4 className="section-title" style={{ color }}>ผลงาน</h4>
              <a href={`/profile/actors/${actor.id}/projects`} target="_blank"><Button className="yt-btn">จัดการผลงาน</Button></a>
            </div>
              <ProjectSlider projects={projects} />
          </Container>
          

          <Container className="info-section">
            <Row>
              <Col md={4}><h4 className="info-title" style={{ color }}>ตำแหน่งที่ตั้ง</h4></Col>
              <Col md={8}>
                <Field name="latlng" component={LatLngField} />
                <label>*กรณีไม่สามารถระบุพิกัดในแผนที่ได้กรุณากรอกที่อยู่ในช่องนี้</label>
                <Field name="address" component="input" className="form-control" placeholder="ที่อยู่ตำแหน่งโครงการ" />

              </Col>
            </Row>
            <Row className="info type">
              <Col md={4}><h4 className="info-title" style={{ color }}>ประเภทกลุ่ม</h4></Col>
              <Col md={8}>
              <Field name="type" component="select" className="form-control">
                {ActorTypes && Object.keys(ActorTypes).map(key => (
                  <option value={key}>{ActorTypes[key].name}</option>
                ))}
              </Field>

              </Col>
            </Row>
            <Row className="info skill-list">
              <Col md={4}><h4 className="info-title" style={{ color }}>ทักษะและงานที่ทำ</h4></Col>
              <Col md={8}>
                <FieldArray name="skills" component={TagsFieldArray} allTags={allSkills} nameLabel="ทักษะ" addLabel="เพิ่มทักษะ" />
              </Col>
            </Row>
            <Row className="info issue-list">
              <Col md={4}><h4 className="info-title" style={{ color }}>ความสนใจ</h4></Col>
              <Col md={8}>
                <FieldArray name="issues" component={TagsFieldArray} allTags={allIssues} nameLabel="ความสนใจ" addLabel="เพิ่มความสนใจ" />
              </Col>
            </Row>
            <Row className="info contacts">
              <Col md={4}><h4 className="info-title" style={{ color }}>ติดต่อ</h4></Col>
              <Col md={8}>
                <Row className="contact">
                  <Col md={3}><label>โทรศัพท์</label></Col>
                  <Col><Field name="tel" component="input" className="form-control" type="text" /></Col>
                </Row>
                <Row className="contact">
                  <Col md={3}><label>E-mail</label></Col>
                  <Col><Field name="email" component="input" className="form-control" type="text" /></Col>
                </Row>
                <Row className="contact">
                  <Col md={3}><label>Line</label></Col>
                  <Col><Field name="lineId" component="input" className="form-control" type="text" /></Col>
                </Row>
                <Row className="contact">
                  <Col md={3}><label>Facebook</label></Col>
                  <Col><Field name="facebook" component="input" className="form-control" type="text" /></Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container className="gallery-section">
            <div className="section-header">
              <h4 className="section-title" style={{ color }}>รูปภาพ</h4>
            </div>
            <Row>
              { images.map((image, i) => (
                <Col md={6} key={i}>
                  <figure className="figure">
                    <img className="figure-img" width="100%" src={image.url} alt={image.description} />
                    <figcaption className="figure-caption">{image.description}</figcaption>
                  </figure>
                </Col>
              ))}
            </Row>
            <UploadImageForm
              name="images"
              multiple={true}
              onSubmit={this.onUploadGallery}
            >
              <div className="add-gallery-area">
                <strong>อัพโหลดรูปภาพ</strong><br />
                คลิกเพื่อเลือกไฟล์รูปบนเครื่อง หรือ ลากไฟล์รูปภาพมาวาง<br />
                (รูปควรมีขนาด 800px x 600px ขึ้นไป และไฟล์มีขนาดไม่เกิน 1MB)
              </div>
            </UploadImageForm>
          </Container>
        </form>
      </Layout>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  findActorProjects,
  findActorImages,
  findTags,
  uploadImages,
  addActorImages,
  removeActorImage,
  change,
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'actor-edit-form' }),
)(ActorEditForm);