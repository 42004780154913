import React, { PureComponent } from 'react';
import { Container } from 'react-bootstrap';

import UserEditForm from '../components/UserEditForm';
import UserEditPasswordForm from '../components/UserEditPasswordForm';

class UserSettingsPage extends PureComponent {
  render() {
    return (
      <div>
        <Container>
          <h1>Settings</h1>

          <h3>General Information</h3>
          <UserEditForm />

          <h3>Change password</h3>
          <UserEditPasswordForm />>
        </Container>
      </div>
    )
  }
}

export default UserSettingsPage;