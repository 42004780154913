import React from 'react';
import Dropzone from 'react-dropzone'

const DropzoneField = ({
  meta: { touched, error, warning },
  input, //redux-form props
  label, className, dropzoneClassname, onFileChange, multiple, children //props
}) => {
  const { value: files, onChange } = input;

  const onDrop = (accepted, rejected) => {
    onChange(accepted);
  };
  return (
    <div className={className}>
      { label &&  <label>{label}</label> }

      { input && 
        <Dropzone
          name={input.name}
          className={dropzoneClassname}
          multiple={ multiple }
          onDrop={onDrop}
          >
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                { children || <p>Drag 'n' drop some files here, or click to select files</p>}
              </div>
            </section>
          )}
        </Dropzone>        
      }

      { touched &&
        (error && <small className="text-danger">{error}</small>)}
    </div>
  )}
export default DropzoneField;