import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '@features/home/components/Layout';
import { countActors } from '@features/actors/reducer';
import { countProjects } from '@features/projects/reducer';

import './AboutPage.scss'


class AboutPage extends PureComponent {
  state = {
    actorCount: 0,
    projectCount: 0,
  }

  async componentDidMount() {
    const { countActors, countProjects } = this.props;
    const actorCount = await countActors();
    const projectCount = await countProjects();
    console.log(projectCount);
    this.setState({
      actorCount,
      projectCount,
    })
  }

  render() {
    const { actorCount, projectCount } = this.state;

    return (
      <Layout className="about-page">
        <Container className="main-container">
          <section>
            <h1 className="page-title">จุด.รวม.ธน<br />แพลต์ฟอร์มเครือข่ายคนฝั่งธน<br />#Peoplenetwork #exchange #collaborate #platform #Thonburi</h1>
            <p className="page-description">คือ พื้นที่กลางที่ให้ทุกคนมาทำความรู้จักกันว่า ใคร ทำอะไร อยู่ที่ไหน เพื่อสร้างความร่วมมือระหว่างเครือข่ายการทำงานพัฒนาที่เกื้อหนุนกัน และสร้างความเป็นไปได้ใหม่ๆ ในการพัฒนาชุมชนฝั่งธนบุรีของเราให้น่าอยู่ขึ้น</p>
            <p className="page-description">นอกจากนี้ยังเป็นจุดเชื่อมต่อระหว่าง หน่วยงาน องค์กร กลุ่มหรือผู้คน กับข้อมูลเพื่อประโยชน์ในการพัฒนาในมิติต่างๆ อย่างเปิดกว้างและหลากหลาย</p>
          </section>
          <section>
            <div className="cover-1-wrapper">
              <div className="cover-1"></div>
            </div>
          </section>

          <section className="actor-project-section">
            <Row>
              <Col md={6} className="col actor-total">
                <h4><span className="left">ตอนนี้มี</span><big>{actorCount || '...'}</big><span className="right">กลุ่ม</span></h4>
              </Col>
              <Col md={6} className="col actor-description">
                <p className="left">คนที่ทำงานพัฒนาที่เกี่ยวข้องพื้นที่ฝั่งธนบุรีในมิติต่างๆ</p>
              </Col>
              <Col md={6} className="col project-total">
                <h4><big>{projectCount || '...'}</big><span className="right">งาน</span></h4>
              </Col>
              <Col md={6} className="col project-description">
                <p className="right">พวกเขามีผลงานโครงการที่พัฒนาชุมชนและเมืองต่างๆ รวมแล้วจำนวน</p>
              </Col>
            </Row>
          </section>

          <section className="outro-section">
            <h2>มาร่วมรวมเป็นส่วนหนึ่งของผู้คนขับเคลื่อนพัฒนาฝั่งธนได้ง่ายๆ เพียงเล่าให้ฟังว่าคุณเป็นใคร สนใจพัฒนาเรื่องอะไรในฝั่งธนบุรี มีผลงานที่เคยทำไว้ ก็สามารถแบ่งปันเรื่องราวของคุณกับเราได้เลย!</h2>
          </section>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  countActors,
  countProjects,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);