import React, { PureComponent } from 'react';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';

import { Map, Marker, isValidCoordinate, defaultCoordinate } from '@components/MapBox';
import ActorMarker from '@components/MapBox/Markers/ActorMarker';

import './LatLngField.scss';

class LatLngField extends PureComponent {

  onLatChange(newLat) {
    const { input: { value, onChange } } = this.props;
    const { coordinates = [0, 0]} = value;
    const [ lat, lng ] = coordinates;
    onChange({
      type: "Point",
      coordinates: [ newLat, lng ],
    });
  }

  onLngChange(newLng) {
    const { input: { value, onChange } } = this.props;
    const { coordinates = [0, 0]} = value;
    const [ lat, lng ] = coordinates;
    onChange({
      type: "Point",
      coordinates: [ lat, newLng ],
    });
  }

  onMapClick(map, e) {
    const { input: { value, onChange } } = this.props;
    const { lngLat } = e;

    onChange({
      type: "Point",
      coordinates: [ lngLat.lng, lngLat.lat ],
    });
  }

  render() {
    const { input, label, height = '300px', required, className, meta: { touched, error, warning} } = this.props;
    const { value = { type: "Point", coordinates: [0, 0] } } = this.props.input;
    const { coordinates = ['', ''] } = value;
    const [ lat = '', lng = ''] = coordinates;
    
    return (
      <Form.Group className={classnames(className, 'form-inline', 'latlng-field', {
          required: required,
        })}>

        <Form.Label className="mr-3">Lat</Form.Label>

        <input
          className="form-control form-control-sm mr-3"
          name="lat"
          type="number"
          onChange={e => this.onLatChange(e.target.value)}
          value={lat}
        />

        <Form.Label className="mr-3">Lng</Form.Label>

        <input
          className="form-control form-control-sm"
          name="lng"
          type="number"
          onChange={e => this.onLngChange(e.target.value)}
          value={lng}
        />

        <div style={{ marginTop: '10px'}}>
          <label>กด double click บนแผนที่เพื่อระบุตำแหน่ง</label>
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
        <Map
          className="latlng-field-map"
          containerStyle={{ width: '100%', height }}
          dblClickZoom={false}
          center={isValidCoordinate(coordinates)? coordinates: defaultCoordinate}
          onDblClick={(map, e) => this.onMapClick(map, e)}
          >
          { isValidCoordinate(coordinates) && (
            <ActorMarker coordinates={coordinates} />
          )}
        </Map>
      </Form.Group>
    )
  }
}

export default LatLngField;