import React, { PureComponent } from 'react';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import Editor from 'draft-js-plugins-editor';

import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createImagePlugin from 'draft-js-image-plugin';

import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import './DraftField.scss';
import { catchClause } from '@babel/types';

class DraftField extends PureComponent {
  constructor(props) {
    super(props);

    const inlineToolbarPlugin = createToolbarPlugin();
    const pluginComponents = {
      Toolbar: inlineToolbarPlugin.Toolbar
    };
    const plugins = [
      inlineToolbarPlugin
    ];

    this.state = {
      editorState: EditorState.createEmpty(),
      plugins,
      ...pluginComponents,
    };
  }  

  componentDidMount() {
    const { input: { value }} = this.props;
    let editorState;
    try {
      const contentState = convertFromRaw(JSON.parse(value));
      editorState = EditorState.createWithContent(contentState);
    } catch (e) {
      const blocksFromHTML = convertFromHTML(value);
      if(blocksFromHTML.contentBlocks.length > 0) {
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );

        editorState = EditorState.createWithContent(state);
      } else {
        editorState = EditorState.createWithContent(ContentState.createFromText(value));
      }
    }
    this.setState({ editorState });
  }

  onChange = editorState => {
    const { input: { onChange }} = this.props;
    this.setState({ editorState });
    onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
  }

  render() {
    const { input, label, type, required, className, meta: { touched, error, warning}, children } = this.props;
    const { editorState, Toolbar, plugins } = this.state;

    return (
      <Form.Group className={classnames(className, 'draft-field', {
          required: required,
        })}>

        <Form.Label>
          {label}
        </Form.Label>

        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        <Toolbar />
        <Editor editorState={editorState} onChange={this.onChange} plugins={plugins} />
      </Form.Group>
    )
  }
}

export default DraftField;