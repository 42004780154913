import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import moment from 'moment-with-locales-es6';
import classnames from 'classnames';
import Layout from '@features/home/components/Layout';
import DraftView from '@components/DraftView';
import { Map, isValidCoordinate, ActorMarker, ProjectMarker, Popup, Layer, Feature } from '@components/MapBox';
import Lightbox from 'react-image-lightbox';
import ActorCard from '@features/actors/components/ActorCard';
import ProjectCard from '@features/projects/components/ProjectCard';
import ProjectSlider from '@features/projects/components/ProjectSlider';
import { getActor, findActorProjects, ActorTypes, findActorImages } from '@features/actors/reducer';

import defaultThumbnail from '@assets/images/defaults/thumbnail.png';
import 'react-image-lightbox/style.css';

moment.locale('th');

class ActorPageView extends PureComponent {
  state = {
    actor: null,
    projects: [],
    projectViewMode: 'list',
    images: [],
    photoIndex: 0,
    showImageModal: false,
  }

  async componentDidMount() {
    const { getActor, findActorProjects, findActorImages, id } = this.props;
    const actor = await getActor(id);
    const projects = await findActorProjects(id);
    const images = await findActorImages(id);

    this.setState({ actor, projects, images });
  }

  setProjectViewMode(projectViewMode) {
    this.setState({ projectViewMode });
  }

  onOpenImageModel(index) {
    this.setState({
      showImageModal: true,
      photoIndex: index,
    })
  }

  render() {
    const { actor, projects, images, projectViewMode, showImageModal, photoIndex } = this.state;
    const { currentUser } = this.props;
    if (!actor) return null;

    const color = ActorTypes[actor.type] && ActorTypes[actor.type].color;

    const editButton = <Link to={`/actors/${actor.id}/${actor.name}/edit`}><Button className="yt-btn">แก้ไข</Button></Link>
    const additionRightMenu = currentUser? [editButton]: [];

    return actor && (
      <Layout className="actor-page view" additionRightMenu={additionRightMenu}>
        <div className="cover-section">
          <div className="cover-wrapper">
            <div className="cover" style={{ backgroundImage: `url(${actor.thumbnail || defaultThumbnail}`}}></div>
          </div>
          <div className="actor-intro">
            <div className="actor-header">
              {actor.logo && (
                <img className="actor-logo" src={actor.logo} alt={actor.name} />
              )}
              <h1 className="actor-name" style={{ color }}>{actor.name}</h1>
              <div className="actor-meta">
                { projects.length > 0 && `${projects.length} งาน | `} เข้าร่วมเมื่อ { moment(actor.updatedAt).format('LL')}
              </div>
            </div>
            <div className="about">
              <DraftView>{actor.description}</DraftView>
            </div>
          </div>
        </div>

        {projects && projects.length > 0 && (
          <Container className="project-list-section">
            <div className="section-header">
              <h4 className="section-title" style={{ color }}>ผลงาน</h4>
              <ul className="nav">
                {projectViewMode !== 'map' && <li onClick={() => this.setProjectViewMode('map')}><i className="icon icon-earth"></i>ดูแผนที่</li>}
                {projectViewMode !== 'list' && <li onClick={() => this.setProjectViewMode('list')}><i className="icon icon-book"></i>ดูโครงการ</li>}
              </ul>
            </div>
            {projectViewMode === 'map'? (
              <div className="map-container">
                <Map center={actor.latlng.coordinates} onClick={() => this.setState({ selected: null })}>
                  {projects && projects.map(project => (project.latlng && isValidCoordinate(project.latlng.coordinates) &&
                    <Fragment>
                      <Layer type="line" layout={{
                        'line-cap': 'round',
                        'line-join': 'round',
                      }} paint={{
                        'line-color': '#888',
                        'line-width': 2
                      }}>
                        <Feature coordinates={[actor.latlng.coordinates, project.latlng.coordinates]} />
                      </Layer>
                      <ProjectMarker
                        coordinates={project.latlng.coordinates}
                        label={project.name}
                        onMouseEnter={() => {
                          this.setState({
                            // center: project.latlng.coordinates,
                            selected: {
                              type: 'project',
                              object: project,
                              coordinates: project.latlng.coordinates,
                              content: <ProjectCard project={project} />,
                            },
                          });
                        }}
                      />
                    </Fragment>
                  ))}

                  <ActorMarker
                    coordinates={actor.latlng.coordinates}
                    actor={actor}
                    label={actor.name}
                    onMouseEnter={() => {
                      this.setState({
                        // center: actor.latlng.coordinates,
                        selected: {
                          type: 'actor',
                          object: actor,
                          coordinates: actor.latlng.coordinates,
                          content: <ActorCard actor={actor} />,
                        },
                      });
                    }}
                  />
                </Map>
              </div>
            ) : (
              <ProjectSlider projects={projects} />
            )}
          </Container>
        )}

        <Container className="info-section">
          {ActorTypes[actor.type] && (
            <Row className="info type">
              <Col md={4}><h4 className="info-title" style={{ color }}>ประเภทกลุ่ม</h4></Col>
              <Col md={8}><h4>{ActorTypes[actor.type].name}</h4></Col>
            </Row>
          )}
          <Row className="info skill-list">
            <Col md={4}><h4 className="info-title" style={{ color }}>ทักษะและงานที่ทำ</h4></Col>
            <Col md={8}>
              { actor.skills && actor.skills.map(skill => (
                <Row className="skill">
                  <Col className="icon-col"><i className={classnames('icon', skill.icon || 'icon-rocket')}></i></Col>
                  <Col>
                    <h4 className="skill-name">{skill.name}</h4>
                    <p className="skill-description">{skill.description}</p>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Row className="info issue-list">
            <Col md={4}><h4 className="info-title" style={{ color }}>ความสนใจ</h4></Col>
            <Col md={8}>
              { actor.issues && actor.issues.map(issue => issue.name).join(', ')}
            </Col>
          </Row>
          <Row className="info contacts">
            <Col md={4}><h4 className="info-title" style={{ color }}>ติดต่อ</h4></Col>
            <Col md={8}>
              { actor.tel && (
                <Row className="contact">
                  <Col md={3}><label>โทรศัพท์</label></Col>
                  <Col><a className="contact-detail" href={`tel:${actor.tel}`}>{actor.tel}</a></Col>
                </Row>
              )}
              { actor.email && (
                <Row className="contact">
                  <Col md={3}><label>E-mail</label></Col>
                  <Col><a className="contact-detail" href={`mailto:${actor.email}`}>{actor.email}</a></Col>
                </Row>
              )}
              { actor.lineId && (
                <Row className="contact">
                  <Col md={3}><label>Line</label></Col>
                  <Col><span className="contact-detail">{actor.lineId}</span></Col>
                </Row>
              )}
              { actor.facebook && (
                <Row className="contact">
                  <Col md={3}><label>Facebook</label></Col>
                  <Col><a className="contact-detail" href={actor.facebook} target="_blank">{actor.facebook}</a></Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>

        {images && images.length > 0 && (
        <Container className="gallery-section">
          <div className="section-header">
            <h4 className="section-title" style={{ color }}>รูปภาพ</h4>
          </div>
          <Row>
            { images.map((image, i) => (
              <Col md={6} key={i}>
                <figure className="figure" onClick={() => this.onOpenImageModel(i)}>
                  <img className="figure-img" width="100%" src={image.url} alt={image.description} />
                  <figcaption className="figure-caption">{image.description}</figcaption>
                </figure>
              </Col>
            ))}
          </Row>

          {showImageModal && (
            <Lightbox
              mainSrc={images[photoIndex].url}
              nextSrc={images[(photoIndex + 1) % images.length].url}
              prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
              onCloseRequest={() => this.setState({ showImageModal: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </Container>
        )}
      </Layout>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  findActorProjects,
  findActorImages,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ActorPageView);