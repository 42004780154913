import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import ActorForm from '../components/ActorForm';
import { getActor, updateActor } from '../reducer';

class ActorEditPage extends Component {
  componentDidMount() {
    const { getActor, id } = this.props;
    getActor(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) return getActor(id);
  }

  onSubmit(values) {
    const { id, actor, updateActor, history } = this.props;
    updateActor(id, {
      ...actor,
      ...values,
      skills: values.skills && Object.keys(values.skills).reduce((result, skill) => ((values.skills[skill])? [ ...result, skill ]: result), []),
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas:  values.areas  && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(res => {
      history.push('/actors');
    })
  }

  render() {
    const { actor } = this.props;
    return (
      <div>
        <Container>
          <h1>Edit Actor</h1>
          {actor && (
            <ActorForm initialValues={{
              ...actor,
              skills: actor.skills && actor.skills.reduce((result, skill) => ({ ...result, [skill]: true }), {}),
              issues: actor.issues && actor.issues.reduce((result, issue) => ({ ...result, [issue]: true }), {}),
              areas:  actor.areas  && actor.areas.reduce((result, area) => ({ ...result, [area]: true }), {}),
            }} onSubmit={values => this.onSubmit(values)} />
          )}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.actor.edit,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  updateActor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorEditPage);