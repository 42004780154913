import api from '@app/api';

export const FIND_NEEDS_REQUEST  = 'action.needs.find.request';
export const FIND_NEEDS_SUCCESS  = 'action.needs.find.success';
export const FIND_NEEDS_ERROR    = 'action.needs.find.error';

export const GET_NEED_REQUEST    = 'action.needs.get.request';
export const GET_NEED_SUCCESS    = 'action.needs.get.success';
export const GET_NEED_ERROR      = 'action.needs.get.error';

export const CREATE_NEED_REQUEST = 'action.needs.create.request';
export const CREATE_NEED_SUCCESS = 'action.needs.create.success';
export const CREATE_NEED_ERROR   = 'action.needs.create.error';

export const UPDATE_NEED_REQUEST = 'action.needs.update.request';
export const UPDATE_NEED_SUCCESS = 'action.needs.update.success';
export const UPDATE_NEED_ERROR   = 'action.needs.update.error';

export const DELETE_NEED_REQUEST = 'action.needs.delete.request';
export const DELETE_NEED_SUCCESS = 'action.needs.delete.success';
export const DELETE_NEED_ERROR   = 'action.needs.delete.error';

const initialState = {
  list: {
    needs: [],
    loading: false,
    error: null,
  },
  new: {
    loading: false,
    error: null,
  },
  edit: {
    need: null,
    loading: false,
    error: null,
  }
};


// Reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case FIND_NEEDS_REQUEST:
      return { ...state, list: { ...state.list,
        loading: true,
        error: null,
      }};

    case FIND_NEEDS_SUCCESS:
      return { ...state, list: { ...state.list,
        needs: action.needs,
        loading: false,
        error: null,
      }};

    case FIND_NEEDS_ERROR:
      return { ...state, list: { ...state.list,
        needs: [],
        loading: false,
        error: action.error,
      }};

    case GET_NEED_REQUEST:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          need: null,
          loading: true,
          error: null,
        }};
      }
      break;

    case GET_NEED_SUCCESS:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          need: action.need,
          loading: false,
          error: null,
        }};
      }
      break;

    case GET_NEED_ERROR:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          need: null,
          loading: false,
          error: action.error,
        }};
      }
      break;
              
    default: break;
  }
  return state;
}

// Actions
export const findNeeds = (params = {}, options = {}) => dispatch => {
  const query = { page: 1, limit: 20, ...params };
  dispatch({ type: FIND_NEEDS_REQUEST, query });
  return api.needs.find(query, options).then(res => {
    const { needs } = res.data;
    dispatch({ type: FIND_NEEDS_SUCCESS, needs });
    return needs;
  }).catch(error => dispatch({ type: FIND_NEEDS_ERROR, error }));
}

export const createNeed = (data, options = {}) => dispatch => {
  dispatch({ type: CREATE_NEED_REQUEST, data });
  return api.needs.create(data, options).then(res => {
    const { need } = res.data;
    dispatch({ type: CREATE_NEED_SUCCESS, need });
    return need;
  }).catch(error => dispatch({ type: CREATE_NEED_ERROR, error }));
}

export const getNeed = (id, options = {}) => dispatch => {
  const { mode = 'edit' } = options;
  dispatch({ type: GET_NEED_REQUEST, id, mode });
  return api.needs.get(id, options).then(res => {
    const { need } = res.data;
    dispatch({ type: GET_NEED_SUCCESS, need, mode });
    return need;
  }).catch(error => dispatch({ type: GET_NEED_ERROR, error, mode }));
}

export const updateNeed = (id, data, options = {}) => dispatch => {
  dispatch({ type: UPDATE_NEED_REQUEST, id, data });
  return api.needs.update(id, data, options).then(res => {
    dispatch({ type: UPDATE_NEED_SUCCESS });
  }).catch(error => dispatch({ type: UPDATE_NEED_ERROR, error }));
}

export const deleteNeed = (id, options = {}) => dispatch => {
  dispatch({ type: DELETE_NEED_REQUEST, id });
  return api.needs.del(id).then(res => {
    dispatch({ type: DELETE_NEED_SUCCESS });
  }).catch(error => {
    dispatch({ type: DELETE_NEED_ERROR, error })
  });
}