import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';

import { findNeeds, deleteNeed } from '../reducer';

class NeedListPage extends PureComponent {
  componentDidMount() {
    const { findNeeds } = this.props;
    findNeeds();
  }

  onDeleteNeed(id) {
    const { deleteNeed, findNeeds } = this.props;
    deleteNeed(id).then(() => {
      findNeeds();
    });
  }

  render() {
    const { needs } = this.props;
    return (
      <div>
        <Container>
          <section className="page-header">
            <h1 className="page-title">Need</h1>
            <ButtonToolbar className="justify-content-between">
              <ButtonGroup>
                <Link to="/needs/new"><Button variant="primary">New</Button></Link>
              </ButtonGroup>
              <ButtonGroup>
              </ButtonGroup>
            </ButtonToolbar>
          </section>
          <section className="page-body">
            <div className="need-list">
              {needs && needs.map(need => (
                <Row key={need.id}>
                  <Col><Link to={`/needs/${need.id}`}>{need.name}</Link></Col>
                  <Col>
                    <ButtonGroup>
                      <Button variant="danger" onClick={e => this.onDeleteNeed(need.id)}>Delete</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              ))}
            </div>
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.need.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  findNeeds,
  deleteNeed,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NeedListPage);