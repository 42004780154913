import React, { PureComponent } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';

import { InputField } from '@components/Field';

class ProjectForm extends PureComponent {

  onSubmit(values) {
    const { handleSubmit } = this.props;
    handleSubmit(values);
  }

  render() {
    return (
      <Form onSubmit={values => this.onSubmit(values)} className="project-edit-form">
        <Form.Group>
          <Form.Label className="font-header">ชื่อ</Form.Label>
          <Field
            name="name"
            className="form-control"
            component="input"
            type="text"
          />
        </Form.Group>

        <Button className="font-header" type="submit" variant="primary" block style={{ backgroundColor: '#29A7DE' }}>สร้าง</Button>
      </Form>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'edit-project',
  }),
)(ProjectForm);