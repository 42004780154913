import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import './TagsFieldArray.scss';

import { Button } from 'react-bootstrap';

class TagsFieldArray extends PureComponent {
  render() {
    const { fields, allTags, nameLabel, addLabel } = this.props;
    return (
      <div className="tags-field-array">
        <div className="tags">
          { fields.map((tag, i) => (
            <div className="tag" key={i}>
              <div className="tag-name form-group">
                <div><label>{nameLabel || 'ชื่อ'}</label></div>
                <Field name={`${tag}.name`} component="select" className="form-control tag-name">
                  <option value="">เลือก</option>
                  { allTags && allTags.map(t => (
                    <option key={t.name} value={t.name}>{t.name}</option>
                  ))}
                </Field>
              </div>
              <div className="tag-description form-group">
                <div><label>คำอธิบาย</label></div>
                <Field name={`${tag}.description`} component="textarea" type="text" className="form-control" placeholder="คำอธิบาย" />
              </div>
            </div>
          ))}
        </div>
        <div>
          <Button className="yt-btn" onClick={() => fields.push({})}><strong>{addLabel || 'Add'}</strong></Button>
        </div>
      </div>
    );
  }
}

export default TagsFieldArray;