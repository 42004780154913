import React, { PureComponent } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';
import classnames from 'classnames';

import { findActors } from '../reducer';

class ActorAddForm extends PureComponent {

  state = {
    actors: null,
  }

  async init() {
    const { findActors } = this.props;
    const actors = await findActors();
    this.setState({ actors });
  }

  componentDidMount() {
    this.init();
  }

  onSubmit(data) {
    const { handleSubmit } = this.props;
    handleSubmit(data);
  }

  render() {
    const { className } = this.props;
    const { actors } = this.state;
    return (
      <Form onSubmit={values => this.onSubmit(values)} className={classnames(className, 'actor-add-form', 'form-inline')}>
        <Form.Group>
          <Form.Label>Actor</Form.Label>
          <Field
            name="actorId"
            className="form-control"
            component="select"
          >
            <option value="">Select</option>
            {actors && actors.map(actor => (
              <option value={actor.id}>{actor.name}</option>
            ))}
          </Field>
        </Form.Group>

        <Button type="submit" variant="primary" >Add</Button>
      </Form>
    )
  }
}

const mapStateToProps = state => {

}

const mapDispatchToProps = dispatch => bindActionCreators({
  findActors,
}, dispatch)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'add-actor',
  }),
)(ActorAddForm);