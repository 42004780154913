import api from '@app/api';

export const FIND_TAGS_REQUEST  = 'action.tags.find.request';
export const FIND_TAGS_SUCCESS  = 'action.tags.find.success';
export const FIND_TAGS_ERROR    = 'action.tags.find.error';

export const GET_TAG_REQUEST    = 'action.tags.get.request';
export const GET_TAG_SUCCESS    = 'action.tags.get.success';
export const GET_TAG_ERROR      = 'action.tags.get.error';

export const CREATE_TAG_REQUEST = 'action.tags.create.request';
export const CREATE_TAG_SUCCESS = 'action.tags.create.success';
export const CREATE_TAG_ERROR   = 'action.tags.create.error';

export const UPDATE_TAG_REQUEST = 'action.tags.update.request';
export const UPDATE_TAG_SUCCESS = 'action.tags.update.success';
export const UPDATE_TAG_ERROR   = 'action.tags.update.error';

export const DELETE_TAG_REQUEST = 'action.tags.delete.request';
export const DELETE_TAG_SUCCESS = 'action.tags.delete.success';
export const DELETE_TAG_ERROR   = 'action.tags.delete.error';

const initialState = {
  list: {
    tags: [],
    loading: false,
    error: null,
  },
  new: {
    loading: false,
    error: null,
  },
  edit: {
    tag: null,
    loading: false,
    error: null,
  }
};


// Reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case FIND_TAGS_REQUEST:
      return { ...state, list: { ...state.list,
        loading: true,
        error: null,
      }};

    case FIND_TAGS_SUCCESS:
      return { ...state, list: { ...state.list,
        tags: action.tags,
        loading: false,
        error: null,
      }};

    case FIND_TAGS_ERROR:
      return { ...state, list: { ...state.list,
        tags: [],
        loading: false,
        error: action.error,
      }};

    case GET_TAG_REQUEST:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          tag: null,
          loading: true,
          error: null,
        }};
      }
      break;

    case GET_TAG_SUCCESS:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          tag: action.tag,
          loading: false,
          error: null,
        }};
      }
      break;

    case GET_TAG_ERROR:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          tag: null,
          loading: false,
          error: action.error,
        }};
      }
      break;
              
    default: break;
  }
  return state;
}

// Actions
export const findTags = (params = {}, options = {}) => dispatch => {
  const query = { ...params };
  dispatch({ type: FIND_TAGS_REQUEST, query });
  return api.tags.find(query, options).then(res => {
    const { tags } = res.data;
    dispatch({ type: FIND_TAGS_SUCCESS, tags });
    return tags;
  }).catch(error => dispatch({ type: FIND_TAGS_ERROR, error }));
}

export const createTag = (data, options = {}) => dispatch => {
  dispatch({ type: CREATE_TAG_REQUEST, data });
  return api.tags.create(data, options).then(res => {
    const { tag } = res.data;
    dispatch({ type: CREATE_TAG_SUCCESS, tag });
    return tag;
  }).catch(error => dispatch({ type: CREATE_TAG_ERROR, error }));
}

export const getTag = (id, options = {}) => dispatch => {
  const { mode = 'edit' } = options;
  dispatch({ type: GET_TAG_REQUEST, id, mode });
  return api.tags.get(id, options).then(res => {
    const { tag } = res.data;
    dispatch({ type: GET_TAG_SUCCESS, tag, mode });
    return tag;
  }).catch(error => dispatch({ type: GET_TAG_ERROR, error, mode }));
}

export const updateTag = (id, data, options = {}) => dispatch => {
  dispatch({ type: UPDATE_TAG_REQUEST, id, data });
  return api.tags.update(id, data, options).then(res => {
    dispatch({ type: UPDATE_TAG_SUCCESS });
  }).catch(error => dispatch({ type: UPDATE_TAG_ERROR, error }));
}

export const deleteTag = (id, options = {}) => dispatch => {
  dispatch({ type: DELETE_TAG_REQUEST, id });
  return api.tags.del(id).then(res => {
    dispatch({ type: DELETE_TAG_SUCCESS });
  }).catch(error => {
    dispatch({ type: DELETE_TAG_ERROR, error })
  });
}