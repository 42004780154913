import React, { PureComponent } from 'react';
import { colors } from '@config/constants';
import classnames from 'classnames';
import './IconCheckboxField.scss';

class IconCheckboxField extends PureComponent {
  handleClick = e => {
    const { input } = this.props;
    input.onChange(!input.value);
  }

  render() {
    const {
      input,
      label,
      icon = 'icon-default-pin',
      color = colors.darkGrey,
    } = this.props;

    return (
      <div className="icon-checkbox-field" onClick={e => this.handleClick(e)}>
        <input type="checkbox" checked={input.value} {...input} />
        <span className="badge" style={{
          borderColor: color,
          backgroundColor: input.value? color: 'transparent',
          color: input.value? 'white': color,
        }}><i className={classnames('icon', icon)}></i></span>
        <span className="badge-label" style={{
          color,
        }}>{label}</span>
      </div>
    )
  }
}

export default IconCheckboxField;