import React, { PureComponent } from 'react';

import ProfileLayout from '../containers/ProfileLayout';
import UserEditForm from '@features/users/components/UserEditForm';

class ProfileSettingsPage extends PureComponent {
  render() {
    return (
      <ProfileLayout>
        <h3>General Settings</h3>
        <UserEditForm />

      </ProfileLayout>
    )
  }
}

export default ProfileSettingsPage;