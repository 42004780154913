import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '@features/home/components/Layout';
import ActorCard from '@features/actors/components/ActorCard';
import './ProjectPage.scss';

import UploadImageForm from '@components/Form/UploadImageForm';
import EditableLatLngForm from '@components/Form/EditableLatLngForm';
import EditableTextForm from '@components/Form/EditableTextForm';
import EditableTagsForm from '@components/Form/EditableTagsForm';
import EditableDraftForm from '@components/Form/EditableDraftForm';
import EditableFlexTimeRangeForm from '@components/Form/EditableFlexTimeRangeForm';

import addThumbnail from '@assets/images/add-thumbnail.png';

import { getProject, updateProject, findProjectActors, findProjectImages, addProjectImages, removeProjectImage } from '@features/projects/reducer';
import { findTags } from '@features/tags/reducer';
import { uploadImages } from '@features/main/reducer';

class ProjectPage extends PureComponent {
  state = {
    project: null,
    actors: [],
    images: [],
    allIssues: [],
  }

  async componentDidMount() {
    const { getProject, findProjectActors, findProjectImages, findTags, id } = this.props;
    const project = await getProject(id);
    const actors = await findProjectActors(id);
    const images = await findProjectImages(id);
    const allIssues = await findTags({ type: 'issue' });
    this.setState({
      project,
      actors,
      images,
      allIssues,
    });
  }

  onUploadThumbnail = async (data) => {
    const { uploadImages } = this.props;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      const thumbnail = images[0];
      this.onEditableSubmit(['thumbnail'], { thumbnail: thumbnail.url });
    }
  }

  onUploadGallery = async (data) => {
    const { id, uploadImages, findProjectImages, addProjectImages } = this.props;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      await addProjectImages(id, { imageIds: images.map(image => image.id) });
      this.setState({ images: await findProjectImages(id) });
    }
  }

  onRemoveGallery = async (imageId) => {
    const { id, removeProjectImage, findProjectImages } = this.props;
    await removeProjectImage(id, imageId);
    this.setState({ images: await findProjectImages(id) });
  }

  onEditableSubmit = (fields, values) => {
    const { updateProject } = this.props;
    const { project } = this.state;
    const data = {
      fields,
      ...values,
    };

    console.log('onEditableSubmit: ', fields, values);

    updateProject(project.id, data, { mode: 'partial' }).then(() => {
      this.setState({
        project: {
          ...project,
          ...values,
        }
      });
    })
  }


  render() {
    const { project, actors, images, allIssues } = this.state;
    return (
      <Layout className="project-page">
        {project && (
          <Fragment>
            <Row className="cover-section">
              <Col md={7}>
                <UploadImageForm name="thumbnail" onSubmit={this.onUploadThumbnail}>
                  <img src={project.thumbnail || addThumbnail} width="100%" alt="" />
                </UploadImageForm>
              </Col>
              <Col md={5}>
                <div className="project-header">
                  <h1 className="project-name">
                    <EditableTextForm
                      form="project-name"
                      name="name"
                      data={project.name}
                      onSubmit={data => this.onEditableSubmit(['name'], { name: data.name })}
                      initialValues={project}
                    />
                  </h1>
                  <div className="project-meta">
                  </div>
                </div>
                <div className="about">
                  <EditableDraftForm
                      form="project-description"
                      name="description"
                      data={project.description}
                      onSubmit={data => this.onEditableSubmit(['description'], { description: data.description })}
                      initialValues={project}
                    />
                </div>
              </Col>
            </Row>

            <Container className="actor-list-section">
              <div className="section-header">
                <h4 className="section-title">คนขับเคลื่อน</h4>
              </div>
              <Row className="actor-list">
                { actors && actors.map(actor => (
                  <Col md={4}>
                    <ActorCard actor={actor} />
                  </Col>
                ))}
              </Row>
            </Container>


            <Container className="info-section">
              <Row className="info latlng">
                <Col md={4}><h4 className="info-title">ตำแหน่งที่ตั้ง</h4></Col>
                <Col md={8}>
                  <EditableLatLngForm
                    form="project-latlng"
                    name="latlng"
                    onSubmit={data => this.onEditableSubmit(['latlng'], { latlng: data.latlng })}
                    initialValues={project}
                  />
                </Col>
              </Row>
              <Row className="info duration">
                <Col md={4}><h4 className="info-title">ระยะเวลา</h4></Col>
                <Col md={8}>
                  <EditableFlexTimeRangeForm
                      name="duration"
                      onSubmit={data => this.onEditableSubmit(['startDate', 'endDate'], { startDate: data.startDate, endDate: data.endDate })}
                      initialValues={project}
                    />
                  
                </Col>
              </Row>
              <Row className="info issue-list">
                <Col md={4}><h4 className="info-title">ประเภทงาน</h4></Col>
                <Col md={8}>
                  <EditableTagsForm
                    form="actor-issues"
                    name="issues"
                    allTags={allIssues}
                    tags={project.issues}
                    initialValues={project}
                    onSubmit={data => this.onEditableSubmit(['issues'], { issues: data.issues })}
                    />
                </Col>
              </Row>
              <Row className="info contact">
                <Col md={4}><h4 className="info-title">ติดต่อ</h4></Col>
                <Col md={8}>
                  <Row>
                    <Col md={3}>โทรศัพท์</Col>
                    <Col>
                      <EditableTextForm
                        form="project-tel"
                        name="tel"
                        data={project.tel}
                        onSubmit={data => this.onEditableSubmit(['tel'], { tel: data.tel })}
                        initialValues={project}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>E-mail</Col>
                    <Col>
                      <EditableTextForm
                        form="project-email"
                        name="email"
                        data={project.email}
                        onSubmit={data => this.onEditableSubmit(['email'], { email: data.email })}
                        initialValues={project}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>Line</Col>
                    <Col>
                      <EditableTextForm
                        form="project-lineId"
                        name="lineId"
                        data={project.lineId}
                        onSubmit={data => this.onEditableSubmit(['lineId'], { lineId: data.lineId })}
                        initialValues={project}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>Facebook</Col>
                    <Col>
                      <EditableTextForm
                        form="project-facebook"
                        name="facebook"
                        data={project.facebook}
                        onSubmit={data => this.onEditableSubmit(['facebook'], { facebook: data.facebook })}
                        initialValues={project}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Container>

            <Container className="gallery-section">
              <div className="section-header">
                <h4 className="section-title">รูปภาพ</h4>
              </div>
              <Row>
                { images && images.map(image => (
                  <Col md={6}>
                    <figure className="figure">
                      <img className="figure-img" width="100%" src={image.url} alt={image.description} />
                      <figcaption className="figure-caption">{image.description}</figcaption>
                      <Button className="btn btn-danger" onClick={() => this.onRemoveGallery(image.id)}>Remove</Button>
                    </figure>
                  </Col>
                ))}
                <Col md={6}>
                  <UploadImageForm
                    name="images"
                    multiple={true}
                    onSubmit={this.onUploadGallery}
                  >
                    <img src={addThumbnail} width="100%" alt="" />
                  </UploadImageForm>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  updateProject,

  findProjectActors,
  findTags,

  uploadImages,

  findProjectImages,
  addProjectImages,
  removeProjectImage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);