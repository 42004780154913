import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

class DraftView extends PureComponent {
  render() {
    const { children, className } = this.props;
    try {
      const json = JSON.parse(children);
      return (
        <div
          className={classnames('draft-view', className)}
          dangerouslySetInnerHTML={{ __html: stateToHTML(convertFromRaw(json)) }}
          />
      );
    } catch (e) {
      return (
        <div>{children}</div>
      )
    }
  }
}
export default DraftView;