import React, { PureComponent } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button, Row, Col } from 'react-bootstrap';

import { InputField, CheckboxField, LatLngField, DraftField } from '@components/Field';

import { findTags } from '@features/tags/reducer';

const actorTypes = {
  PERSON: 'บุคคลธรรมดา',
  PRIVATE_GROUP: 'ชุมชน,กลุ่มภาคประชาสังคม',
  PRIVATE_ORGANIZATION: 'สมาคม, มูลนิธิ, องค์กรสาธารประโยชน์ (Social enterprise)',
  PUBLIC_ORGANIZATIOn: 'บริษัท, ห้างหุ้นส่วน',
  EDUCATION_INSTITUTION: 'สถาบันการศึกษาทั้งของรัฐและเอกชน',
  GOVERNMENT: 'หน่วยงานภาครัฐบาล',
};

const provinces = [];
const amphoes = [];
const tambons = [];

class ActorForm extends PureComponent {

  state = {
    skills: [],
    issues: [],
    areas: [],
  };

  componentDidMount = async () => {
    const { findTags } = this.props;
    const skills = await findTags({ type: 'skill'});
    const issues = await findTags({ type: 'issue'});
    const areas = await findTags({ type: 'area'});
    this.setState({ skills, issues, areas });
  }

  onSubmit(e) {
    const { handleSubmit } = this.props;
    handleSubmit(e);
  }

  render() {
    const { skills, issues, areas } = this.state;

    return (
      <Form onSubmit={e => this.onSubmit(e)} className="actor-edit-form">
        <h4>General Information</h4>

        <Field name="name" label="Name" component={InputField} type="text" required />

        <Field name="type" label="Type" component={InputField} type="select" required >
          <option value="">Select</option>
            {Object.keys(actorTypes).map(key => (
              <option value={key} key={key}>{actorTypes[key]}</option>
            ))}
        </Field>

        <Field name="description" label="Description" component={DraftField} required />
        
        <h4>Location</h4>

        <Row>
          <Col>

            <Field name="province" label="Province" component={InputField} type="select">
              <option value="">Select</option>
              {provinces.map(province => (
                <option value={province.value} key={province.value}>{province.text}</option>
              ))}
            </Field>

            <Field name="amphoe" label="Amphoe" component={InputField} type="select">
              <option value="">Select</option>
              {amphoes.map(amphoe => (
                <option value={amphoe.value} key={amphoe.value}>{amphoe.text}</option>
              ))}
            </Field>

            <Field name="tambon" label="Tambon" component={InputField} type="select">
              <option value="">Select</option>
              {tambons.map(tambon => (
                <option value={tambon.value} key={tambon.value}>{tambon.text}</option>
              ))}
            </Field>

          </Col>
          <Col>
            <Field name="latlng" component={LatLngField} />
          </Col>
        </Row>
        <Field name="publishLocation" label="Publish Location" component={CheckboxField} />

        <h4>Contact</h4>

        <Field name="tel" label="Tel" component={InputField} type="text" />
        <Field name="email" label="E-mail" component={InputField} type="email" />
        <Field name="facebook" label="Facebook" component={InputField} type="text" />
        <Field name="lineId" label="Line ID" component={InputField} type="text" />

        <Row>
          <Col md={4}>
            <h4>Skills &amp; Resources</h4>

            <div>
            {skills && skills.map(tag => (
              <Field key={tag.name} name={`skills[${tag.name}]`} label={tag.name} component={CheckboxField} />
            ))}
            </div>
          </Col>
          <Col md={4}>
            <h4>Interest issues</h4>

            <div>
            {issues && issues.map(tag => (
              <Field key={tag.name} name={`issues[${tag.name}]`} label={tag.name} component={CheckboxField} />
            ))}
            </div>
          </Col>
          <Col md={4}>
            <h4>Focused areas</h4>

            <div>
            {areas && areas.map(tag => (
              <Field key={tag.name} name={`areas[${tag.name}]`} label={tag.name} component={CheckboxField} />
            ))}
            </div>
          </Col>
        </Row>

        <Button type="submit" variant="primary" block >Submit</Button>
      </Form>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  findTags,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'edit-actor' }),
)(ActorForm);