import React from 'react';
import classnames from 'classnames';
import { ActorTypes } from '@features/actors/reducer';
import { Marker } from '@components/MapBox';
import './ActorMarker.scss';

const ActorMarker = ({ actor, label, className, ...props}) => (
  <Marker
    className={classnames("actor-marker", className)}
    offset={{
      'bottom': [0, 50],
    }}
    style={{ color: actor && ActorTypes[actor.type] && ActorTypes[actor.type].color }}
    {...props}
    >
    <div><i className="icon icon-default-pin"></i></div>
    {label && (<label>{label}</label>)}
  </Marker>
);

export default ActorMarker;