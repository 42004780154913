import { mapbox } from '@config/api';

export const defaultCoordinate = mapbox.defaultCoordinate;

export const isValidCoordinate = (coordinate) => {
  if (!coordinate) return false;
  
  const [lng, lat] = coordinate;
  return typeof lat === 'number'
         && typeof lng === 'number'
         && lng >= -180
         && lng <= 180
         && lat >= -90
         && lat <= 90;
}