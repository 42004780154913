import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';

class TagForm extends PureComponent {

  onSubmit(values) {
    const { handleSubmit } = this.props;
    if (handleSubmit) handleSubmit(values);
  }

  render() {
    return (
      <Form onSubmit={values => this.onSubmit(values)} className="tag-form form-inline">
        <Form.Group>
          <Field
            name="name"
            className="form-control"
            component="input"
            type="text"
            placeholder="New Tag"
          />
        </Form.Group>

        <Button type="submit" variant="primary" >Submit</Button>
      </Form>
    )
  }
}
export default compose(
  reduxForm({}),
)(TagForm);