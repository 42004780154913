import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { Map, Marker, isValidCoordinate, ActorMarker, ProjectMarker } from '@components/MapBox';
import Layout from '@features/home/components/Layout';
import { findActors } from '@features/actors/reducer';
import { findProjects } from '@features/projects/reducer';

class MapPage extends PureComponent {

  state = {
    actors: [],
    projects: [],
  };

  async componentDidMount() {
    const { findActors, findProjects } = this.props;
    const actors = await findActors();
    const projects = await findProjects();
    this.setState({ actors, projects });
  }

  render() {
    const { actors, projects } = this.state;
    return (
      <Layout>
        <Map>
          {actors && actors.map(actor => (actor.latlng && isValidCoordinate(actor.latlng.coordinates) &&
            <ActorMarker coordinates={actor.latlng.coordinates} />
          ))}
          {projects && projects.map(project => (project.latlng && isValidCoordinate(project.latlng.coordinates) &&
            <ProjectMarker coordinates={project.latlng.coordinates} />
          ))}
        </Map>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  findActors,
  findProjects,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);