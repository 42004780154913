import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { reduxForm, change, Field, Fields, FieldArray } from 'redux-form';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import moment from 'moment-with-locales-es6';
import Layout from '@features/home/components/Layout';
import DraftView from '@components/DraftView';
import { Map, isValidCoordinate, ActorMarker, ProjectMarker, Popup, Layer, Feature } from '@components/MapBox';
import ActorCard from '@features/actors/components/ActorCard';
import ProjectCard from '@features/projects/components/ProjectCard';
import ProjectSlider from '@features/projects/components/ProjectSlider';

// Actions
import { getProject, findProjectActors, findProjectImages, addProjectImages, removeProjectImage } from '@features/projects/reducer';
import { findTags } from '@features/tags/reducer';
import { uploadImages } from '@features/main/reducer';

// Form
import defaultThumbnail from '@assets/images/defaults/thumbnail.png';
import addThumbnail from '@assets/images/add-thumbnail.png';

import TagsFieldArray from '@components/Field/TagsFieldArray';
import DateRangePickerField from '@components/Field/DateRangePickerField';
import { DraftField, LatLngField } from '@components/Field';
import UploadImageForm from '@components/Form/UploadImageForm';

moment.locale('th');

class ProjectEditForm extends PureComponent {
  state = {
    project: null,
    actors: [],
    images: [],
    allSkills: [],
    allIssues: [],
  }

  async componentDidMount() {
    const { getProject, findProjectActors, findProjectImages, findTags, id } = this.props;
    const project = await getProject(id);
    const actors = await findProjectActors(id);
    const images = await findProjectImages(id);
    const allSkills = await findTags({ type: 'skill' });
    const allIssues = await findTags({ type: 'issue' });

    this.setState({ project, actors, images, allSkills, allIssues });
  }

  setProjectViewMode(projectViewMode) {
    this.setState({ projectViewMode });
  }

  onUploadThumbnail = async (data) => {
    const { uploadImages, change } = this.props;
    const { project } = this.state;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      const thumbnail = images[0].url;
      change('thumbnail', thumbnail);
      this.setState({
        project: {
          ...project,
          thumbnail,
        }
       });
    }
  }

  onUploadLogo = async (data) => {
    const { uploadImages, change } = this.props;
    const { project } = this.state;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      const logo = images[0].url;
      change('logo', logo);
      this.setState({
        project: {
          ...project,
          logo,
        }
       });
      
    }
  }

  onUploadGallery = async (data) => {
    const { id, uploadImages, findProjectImages, addProjectImages } = this.props;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      await addProjectImages(id, { imageIds: images.map(image => image.id) });
      this.setState({ images: await findProjectImages(id) });
    }
  }

  onRemoveGallery = async (imageId) => {
    const { id, removeProjectImage, findProjectImages } = this.props;
    await removeProjectImage(id, imageId);
    this.setState({ images: await findProjectImages(id) });
  }


  render() {
    const { handleSubmit } = this.props;
    const { project, actors, images, allSkills, allIssues, projectViewMode } = this.state;
    if (!project) return null;

    const saveButton = <Button className="yt-btn" onClick={handleSubmit}>บันทึก</Button>

    return project && (
      <Layout className="project-page edit" additionRightMenu={[saveButton]}>
        <form onSubmit={handleSubmit} className="project-edit-form">
          <div className="cover-section">
            <div className="cover-wrapper">
              <label>รูป Cover</label>
              <div><small>คลิกเพื่อเลือกไฟล์รูปบนเครื่อง หรือ ลากไฟล์รูปภาพมาวาง (รูปควรมีขนาด 1200px x 800px ขึ้นไป และไฟล์มีขนาดไม่เกิน 1MB)</small></div>

              <UploadImageForm name="thumbnail" onSubmit={this.onUploadThumbnail}>
                <img src={project.thumbnail || defaultThumbnail} width="100%" alt="" />
              </UploadImageForm>
            </div>
            <div className="project-intro">
              <div className="project-header">
                <div>
                  <label>ชื่อโครงการ</label>
                  <Field name="name" component="input" className="form-control" type="text" placeholder="ชื่อโครงการ" />
                </div>
              </div>
              <div className="about">
                <label className="font-header"><strong>แนะนำโครงการ</strong></label>
                <Field name="description" component="textarea" className="form-control" />
              </div>
            </div>
          </div>

          
          <Container className="actor-list-section">
            <div className="section-header">
              <h4 className="section-title">คนขับเคลื่อน</h4>
            </div>
              <Row className="actor-list">
                { actors.map(actor => (
                  <Col md={4}>
                    <ActorCard actor={actor} />
                  </Col>
                ))}
              </Row>
          </Container>
          

          <Container className="info-section">
            <Row>
              <Col md={4}><h4 className="info-title">ตำแหน่งที่ตั้ง</h4></Col>
              <Col md={8}>
                <Form.Group>
                  <Field name="latlng" component={LatLngField} />
                  <label>*กรณีไม่สามารถระบุพิกัดในแผนที่ได้กรุณากรอกที่อยู่ในช่องนี้</label>
                  <Field name="address" component="input" className="form-control" placeholder="ที่อยู่ตำแหน่งโครงการ" />
                </Form.Group>
              </Col>
            </Row>
            <Row className="info date">
              <Col md={4}><h4 className="info-title">ระยะเวลา</h4></Col>
              <Col md={8}>
                <Fields
                  names={['startDate', 'endDate']}
                  component={DateRangePickerField}
                  startDateId="startDate"
                  endDateId="endDate"
                  focusedInput
                  isOutsideRange={this.handleOutsideRange}
                  onClose={this.onClose}
                  />

              </Col>
            </Row>
            <Row className="info issue-list">
              <Col md={4}><h4 className="info-title">ประเภทงาน</h4></Col>
              <Col md={8}>
                <FieldArray name="issues" component={TagsFieldArray} allTags={allIssues} addLabel="เพิ่มประเภทงาน" />
              </Col>
            </Row>
            <Row className="info contacts">
              <Col md={4}><h4 className="info-title">ติดต่อผู้ประสานงาน</h4></Col>
              <Col md={8}>
              <Row className="contact">
                  <Col md={3}><label>ชื่อผู้ประสานงาน</label></Col>
                  <Col><Field name="contactPersonName" component="input" className="form-control" type="text" /></Col>
                </Row>
                <Row className="contact">
                  <Col md={3}><label>โทรศัพท์</label></Col>
                  <Col><Field name="tel" component="input" className="form-control" type="text" /></Col>
                </Row>
                <Row className="contact">
                  <Col md={3}><label>E-mail</label></Col>
                  <Col><Field name="email" component="input" className="form-control" type="text" /></Col>
                </Row>
                <Row className="contact">
                  <Col md={3}><label>Line</label></Col>
                  <Col><Field name="lineId" component="input" className="form-control" type="text" /></Col>
                </Row>
                <Row className="contact">
                  <Col md={3}><label>Facebook</label></Col>
                  <Col><Field name="facebook" component="input" className="form-control" type="text" /></Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container className="gallery-section">
            <div className="section-header">
              <h4 className="section-title">รูปภาพ</h4>
            </div>
            <Row>
              { images.map((image, i) => (
                <Col md={6} key={i}>
                  <figure className="figure">
                    <img className="figure-img" width="100%" src={image.url} alt={image.description} />
                    <figcaption className="figure-caption">{image.description}</figcaption>
                  </figure>
                </Col>
              ))}
            </Row>
            <UploadImageForm
              name="images"
              multiple={true}
              onSubmit={this.onUploadGallery}
            >
              <div className="add-gallery-area">
                <strong>อัพโหลดรูปภาพ</strong><br />
                คลิกเพื่อเลือกไฟล์รูปบนเครื่อง หรือ ลากไฟล์รูปภาพมาวาง<br />
                (รูปควรมีขนาด 800px x 600px ขึ้นไป และไฟล์มีขนาดไม่เกิน 1MB)

              </div>
            </UploadImageForm>
          </Container>
        </form>
      </Layout>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  findProjectActors,
  findProjectImages,
  findTags,
  uploadImages,
  addProjectImages,
  removeProjectImage,
  change,
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'project-edit-form' }),
)(ProjectEditForm);