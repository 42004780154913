import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';

import './LoginForm.scss';

class LoginForm extends PureComponent {

  onSubmit(data) {
    const { handleSubmit } = this.props;
    handleSubmit(data);
  }

  render() {
    return (
      <Form onSubmit={data => this.onSubmit(data)} className="login-form">
        <h1>เข้าใช้งาน</h1>
        <Form.Group>
          <Form.Label>อีเมล</Form.Label>
          <Field
            name="email"
            className="form-control"
            component="input"
            type="email"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>รหัสผ่าน</Form.Label>
          <Field
            name="password"
            className="form-control"
            component="input"
            type="password"
          />
        </Form.Group>
        <Button type="submit" variant="primary" block >เข้าใช้งาน</Button>
      </Form>
    )
  }
}
export default compose(
  reduxForm({
    form: 'login',
  }),
)(LoginForm);