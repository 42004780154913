import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import ProjectForm from '../components/ProjectForm';
import { getProject, updateProject } from '../reducer';

class ProjectEditPage extends Component {
  componentDidMount() {
    const { getProject, id } = this.props;
    getProject(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) return getProject(id);
  }

  onSubmit(values) {
    const { id, project, updateProject, history } = this.props;
    updateProject(id, {
      ...project,
      ...values,
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas: values.areas && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(res => {
      history.push('/projects');
    })
  }

  render() {
    const { project } = this.props;
    return (
      <div>
        <Container>
          <h1>Edit Project</h1>
          {project && (
            <ProjectForm initialValues={{
              ...project,
              issues: project.issues && project.issues.reduce((result, issue) => ({ ...result, [issue]: true }), {}),
              areas: project.areas && project.areas.reduce((result, area) => ({ ...result, [area]: true }), {}),
            }} onSubmit={values => this.onSubmit(values)} />
          )}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.project.edit,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  updateProject,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditPage);