import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Container } from 'react-bootstrap';
import NeedForm from '../components/NeedForm';
import { createNeed } from '../reducer';

class NeedNewPage extends PureComponent {

  onSubmit(values) {
    const { createNeed, history } = this.props;
    const { actorId, projectId } = this.props.queries;

    createNeed({
      ...values,
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas: values.areas && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(() => {
      if (projectId) history.push(`/projects/${projectId}/settings/needs`);
      else if(actorId) history.push(`/actors/${actorId}/needs`);
      else history.push('/needs');
    });
  }

  render() {
    const { actorId, projectId } = this.props.queries;

    return (
      <div>
        <Container>
          <section className="page-header">
            <h1>New Need</h1>
          </section>
          <section className="page-body">
            <NeedForm onSubmit={values => this.onSubmit(values)} initialValues={{ actorId, projectId }} />
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  createNeed,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NeedNewPage);