import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';

class UserEditForm extends PureComponent {

  onSubmit(values) {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit(values);
  }

  render() {
    return (
      <Form onSubmit={values => this.onSubmit(values)} className="user-edit-form">
        <Form.Group>
          <Form.Label>Old Password</Form.Label>
          <Field
            name="old_password"
            className="form-control"
            component="input"
            type="password"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>New Password</Form.Label>
          <Field
            name="password"
            className="form-control"
            component="input"
            type="password"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Confirm New Password</Form.Label>
          <Field
            name="confirm_password"
            className="form-control"
            component="input"
            type="password"
          />
        </Form.Group>

        <Button type="submit" variant="primary" block >Submit</Button>
      </Form>
    )
  }
}
export default compose(
  reduxForm({
    form: 'edit-user',
  }),
)(UserEditForm);