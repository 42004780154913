import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col, Button } from 'react-bootstrap';

import { ActorAddForm } from '@features/actors';
import ProjectSettingsLayout from '../containers/ProjectSettingsLayout';
import { getProject, findProjectActors, addProjectActor, removeProjectActor } from '../reducer';

class ProjectSettingsActorListPage extends PureComponent {

  state = {
    project: null,
    actors: null,
  }

  async init() {
    const { id, getProject, findProjectActors } = this.props;
    const project = await getProject(id);
    const actors = await findProjectActors(id);
    this.setState({ project, actors });
  }

  componentDidMount() {
    this.init();
  }

  onAddActor(data) {
    const { id, addProjectActor } = this.props;
    addProjectActor(id, data).then(() => this.init());
  }

  onRemoveActor(userId) {
    const { id, removeProjectActor } = this.props;
    removeProjectActor(id, userId).then(() => this.init());
  }

  render() {
    const { project, actors } = this.state;
    return (
      <Fragment>
        {project && (
          <ProjectSettingsLayout project={project}>
            <h3>Actors</h3>

            {actors && actors.map(actor => (
              <Row>
                <Col>{actor.name}</Col>
                <Col><Button variant="danger" onClick={e => this.onRemoveActor(actor.id)}>Remove</Button></Col>
              </Row>
            ))}

            <ActorAddForm onSubmit={data => this.onAddActor(data)} />
          </ProjectSettingsLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  findProjectActors,
  addProjectActor,
  removeProjectActor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsActorListPage);