import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import IconCheckboxField from '@components/Field/IconCheckboxField';
import { ActorTypes } from '@features/actors/reducer';
import { findTags } from '@features/tags/reducer';
import { colors } from '@config/constants';
import './ActorFilterForm.scss';

const types = Object.keys(ActorTypes).map(key => ActorTypes[key]);

class ActorFilterForm extends PureComponent {

  state = {
    issues: [],
  }

  async componentDidMount() {
    const { findTags } = this.props;
    const issues = await findTags({ type: 'issue'});
    this.setState({ issues });
  }

  onChange(_, field, value) {
    const { handleSubmit, change } = this.props;
    const { issues } = this.state;
    setTimeout(() => {
      if (field === 'types') {
        if (this.isCheckingAllTypes()) {
          change('types', {
            ...types.reduce((result, type) => ({ ...result, [type.id]: false }), { all: false }),
            [value]: true,
          });
        } else {
          this.isAllTypes();
        }
      } else if (field === 'issues') {
        if (this.isCheckingAllIssues()) {
          change('issues', {
            ...issues.reduce((result, issue) => ({ ...result, [issue.name]: false }), { all: false }),
            [value]: true,
          })
        } else {
          this.isAllIssues();
        }
      }
      
      handleSubmit();
    }, 10);
  }

  setAllTypes(value) {
    const { change } = this.props;
    setTimeout(() => {
      change('types', types.reduce((result, type) => ({ ...result, [type.id]: value }), { all: value }))
      this.onChange();
    }, 10)
  }

  isAllTypes() {
    const { selectedTypes, change } = this.props;
    const { all: oldFlag } = selectedTypes;
    const newFlag = Object.keys(selectedTypes).filter(key => key !== 'all').reduce((result, key) => (result && selectedTypes[key]), true);
    console.log('oldFlag: ', oldFlag, ', new: ', newFlag);
    if (oldFlag !== newFlag) change('types', { ...selectedTypes, all: newFlag });
  }

  isCheckingAllTypes() {
    const { selectedTypes } = this.props;
    const { all } = selectedTypes;
    return !!all;
  }

  setAllIssues(value) {
    const { change } = this.props;
    const { issues } = this.state;
    setTimeout(() => {
      change('issues', issues.reduce((result, issue) => ({ ...result, [issue.name]: value }), { all: value }))
      this.onChange();
    }, 10)
  }

  isAllIssues() {
    const { selectedIssues, change } = this.props;
    const { all: oldFlag } = selectedIssues;
    const newFlag = Object.keys(selectedIssues).filter(key => key !== 'all').reduce((result, key) => (result && selectedIssues[key]), true);
    if (oldFlag !== newFlag) change('issues', { ...selectedIssues, all: newFlag });
  }

  isCheckingAllIssues() {
    const { selectedIssues } = this.props;
    const { all } = selectedIssues;
    return !!all;
  }


  render() {
    const { issues } = this.state;
    const { selectedTypes, selectedIssues, handleSubmit } = this.props;
    return (
      <form className="actor-filter-form filter-panel" onSubmit={handleSubmit}>
        <h4>ใคร</h4>
        <Row className="filter-type">
          <Col xs={12}>
            <Field  name={`types[all]`}
                    component={IconCheckboxField}
                    label={"ดูทั้งหมด"}
                    onChange={() => this.setAllTypes(!selectedTypes.all)} />
          </Col>
          { types && types.map(type => (
            <Col xs={12} key={type.id}>
              <Field
                name={`types[${type.id}]`}
                component={IconCheckboxField}
                label={type.name}
                color={type.color}
                onChange={e => this.onChange(e, 'types', type.id)}
              />
            </Col>
          ))}
        </Row>

        <h4>ทำอะไร</h4>
        <Row className="filter-issue">
          <Col xs={12}>
            <Field  name={`issues[all]`}
                    component={IconCheckboxField} 
                    label={"ดูทั้งหมด"} 
                    color={colors.purple}
                    onChange={() => this.setAllIssues(!selectedIssues.all)} />
          </Col>
          { issues && issues.map(issue => (
            <Col xs={12} key={issue.name}>
              <Field  name={`issues[${issue.name}]`}
                      component={IconCheckboxField}
                      label={issue.name}
                      icon={issue.icon}
                      color={colors.purple}
                      onChange={e => this.onChange(e, 'issues', issue.name)}
              />
            </Col>
          ))}
        </Row>
      </form>
    );
  }
}

const formName = 'actor-filter-form';
const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  selectedTypes: selector(state, 'types'),
  selectedIssues: selector(state, 'issues'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  change,
  findTags,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: formName }),
)(ActorFilterForm);