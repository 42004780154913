import { colors } from '@config/constants';
import api from '@app/api';

//###################### Constant  ######################//

export const FIND_ACTORS_REQUEST  = 'action.actors.find.request';
export const FIND_ACTORS_SUCCESS  = 'action.actors.find.success';
export const FIND_ACTORS_ERROR    = 'action.actors.find.error';

export const GET_ACTOR_REQUEST    = 'action.actors.get.request';
export const GET_ACTOR_SUCCESS    = 'action.actors.get.success';
export const GET_ACTOR_ERROR      = 'action.actors.get.error';

export const COUNT_ACTOR_REQUEST    = 'action.actors.count.request';
export const COUNT_ACTOR_SUCCESS    = 'action.actors.count.success';
export const COUNT_ACTOR_ERROR      = 'action.actors.count.error';

export const CREATE_ACTOR_REQUEST = 'action.actors.create.request';
export const CREATE_ACTOR_SUCCESS = 'action.actors.create.success';
export const CREATE_ACTOR_ERROR   = 'action.actors.create.error';

export const UPDATE_ACTOR_REQUEST = 'action.actors.update.request';
export const UPDATE_ACTOR_SUCCESS = 'action.actors.update.success';
export const UPDATE_ACTOR_ERROR   = 'action.actors.update.error';

export const DELETE_ACTOR_REQUEST = 'action.actors.delete.request';
export const DELETE_ACTOR_SUCCESS = 'action.actors.delete.success';
export const DELETE_ACTOR_ERROR   = 'action.actors.delete.error';

export const FIND_ACTOR_PROJECTS_REQUEST  = 'action.actors.find.projects.request';
export const FIND_ACTOR_PROJECTS_SUCCESS  = 'action.actors.find.projects.success';
export const FIND_ACTOR_PROJECTS_ERROR    = 'action.actors.find.projects.error';

export const FIND_ACTOR_MEMBERS_REQUEST  = 'action.actors.find.members.request';
export const FIND_ACTOR_MEMBERS_SUCCESS  = 'action.actors.find.members.success';
export const FIND_ACTOR_MEMBERS_ERROR    = 'action.actors.find.members.error';

export const ADD_ACTOR_MEMBER_REQUEST  = 'action.actors.add.member.request';
export const ADD_ACTOR_MEMBER_SUCCESS  = 'action.actors.add.member.success';
export const ADD_ACTOR_MEMBER_ERROR    = 'action.actors.add.member.error';

export const REMOVE_ACTOR_MEMBER_REQUEST  = 'action.actors.remove.member.request';
export const REMOVE_ACTOR_MEMBER_SUCCESS  = 'action.actors.remove.member.success';
export const REMOVE_ACTOR_MEMBER_ERROR    = 'action.actors.removve.member.error';

export const FIND_ACTOR_ARTICLES_REQUEST  = 'action.actors.find.articles.request';
export const FIND_ACTOR_ARTICLES_SUCCESS  = 'action.actors.find.articles.success';
export const FIND_ACTOR_ARTICLES_ERROR    = 'action.actors.find.articles.error';

export const FIND_ACTOR_IMAGES_REQUEST = 'action.actors.find.images.request';
export const FIND_ACTOR_IMAGES_SUCCESS = 'action.actors.find.images.success';
export const FIND_ACTOR_IMAGES_ERROR   = 'action.actors.find.images.error';

export const ADD_ACTOR_IMAGES_REQUEST = 'action.actors.add.images.request';
export const ADD_ACTOR_IMAGES_SUCCESS = 'action.actors.add.images.success';
export const ADD_ACTOR_IMAGES_ERROR   = 'action.actors.add.images.error';

export const REMOVE_ACTOR_IMAGES_REQUEST = 'action.actors.remove.images.request';
export const REMOVE_ACTOR_IMAGES_SUCCESS = 'action.actors.remove.images.success';
export const REMOVE_ACTOR_IMAGES_ERROR   = 'action.actors.remove.images.error';

export const ActorTypes = {
  PERSON: {
    id: 'PERSON',
    name: 'บุคคลทั่วไป',
    description: 'บุคคลธรรมดา',
    color: colors.blue,
    icon: '',
  },
  PRIVATE_GROUP: {
    id: 'PRIVATE_GROUP',
    name: 'กลุ่ม',
    description: 'ชุมชน,กลุ่มภาคประชาสังคม',
    color: colors.terquoise,
    icon: '',
  },
  PUBLIC_ORGANIZATION: {
    id: 'PUBLIC_ORGANIZATION',
    name: 'องค์กร',
    description: 'สมาคม, มูลนิธิ, องค์กรสาธารประโยชน์ (Social enterprise)',
    color: colors.green,
    icon: '',
  },
  PRIVATE_ORGANIZATION: {
    id: 'PRIVATE_ORGANIZATION',
    name: 'เอกชน',
    description: 'บริษัท, ห้างหุ้นส่วน',
    color: colors.yellow,
    icon: '',
  },
  EDUCATION_INSTITUTION: {
    id: 'EDUCATION_INSTITUTION',
    name: 'สถาบันการศึกษา',
    description: 'สถาบันการศึกษาทั้งของรัฐและเอกชน',
    color: colors.orange,
    icon: '',
  },
  GOVERNMENT: {
    id: 'GOVERNMENT',
    name: 'รัฐ',
    description: 'หน่วยงานภาครัฐบาล',
    color: colors.red,
    icon: '',
  },
};

//########################## Reducer #############################//

const initialState = {
  list: {
    actors: [],
    loading: false,
    error: null,
  },
  new: {
    loading: false,
    error: null,
  },
  edit: {
    actor: null,
    loading: false,
    error: null,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {

    case FIND_ACTORS_REQUEST:
      return { ...state, list: { ...state.list,
        loading: true,
        error: null,
      }};

    case FIND_ACTORS_SUCCESS:
      return { ...state, list: { ...state.list,
        actors: action.actors,
        loading: false,
        error: null,
      }};

    case FIND_ACTORS_ERROR:
      return { ...state, list: { ...state.list,
        actors: [],
        loading: false,
        error: action.error,
      }};

    case GET_ACTOR_REQUEST:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          actor: null,
          loading: true,
          error: null,
        }};
      }
      break;

    case GET_ACTOR_SUCCESS:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          actor: action.actor,
          loading: false,
          error: null,
        }};
      }
      break;

    case GET_ACTOR_ERROR:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          actor: null,
          loading: false,
          error: action.error,
        }};
      }
      break;
              
    default: break;
  }
  return state;
}

// Actions
export const findActors = (params = {}, options = {}) => dispatch => {
  let query = {
    page: 1,
    limit: 20,
  };
  
  
  if (params.types && !params.types.all) {
    if (Object.keys(params.types).filter(key => params.types[key]).length === 0) {
      query['types'] = 'none';
    }
    else {
      query['types'] = Object.keys(params.types).filter(key => (params.types[key] && key !== 'all')).join(',');
    }
  }
  

  if (params.issues && !params.issues.all) {
    if (Object.keys(params.issues).filter(key => params.issues[key]).length === 0) {
      query['issues'] = 'none';
    }
    else {
      query['issues'] = Object.keys(params.issues).filter(key => (params.issues[key] && key !== 'all')).join(',');
    }
  }
  

  dispatch({ type: FIND_ACTORS_REQUEST, query });
  return api.actors.find(query, options).then(res => {
    const { actors } = res.data;
    dispatch({ type: FIND_ACTORS_SUCCESS, actors });
    return actors;
  }).catch(error => dispatch({ type: FIND_ACTORS_ERROR, error }));
}

export const countActors = () => dispatch => {
  dispatch({ type: COUNT_ACTOR_REQUEST });
  return api.actors.count().then(res => {
    const { count } = res.data;
    dispatch({ type: COUNT_ACTOR_SUCCESS, count });
    return count;
  })
}

export const createActor = (data, options = {}) => dispatch => {
  dispatch({ type: CREATE_ACTOR_REQUEST, data });
  return api.actors.create(data, options).then(res => {
    console.log('api.createActor.success');
    const { actor } = res.data;
    dispatch({ type: CREATE_ACTOR_SUCCESS, actor });
    return actor;
  }).catch(error => {
    dispatch({ type: CREATE_ACTOR_ERROR, error })
    throw error;
  });
}

export const getActor = (id, options = {}) => dispatch => {
  const { mode = 'edit' } = options;
  dispatch({ type: GET_ACTOR_REQUEST, id, mode });
  return api.actors.get(id, options).then(res => {
    const { actor } = res.data;
    dispatch({ type: GET_ACTOR_SUCCESS, actor, mode });
    return actor;
  }).catch(error => dispatch({ type: GET_ACTOR_ERROR, error, mode }));
}

export const updateActor = (id, data, options = {}) => dispatch => {
  dispatch({ type: UPDATE_ACTOR_REQUEST, id, data });
  return api.actors.update(id, data, options).then(res => {
    dispatch({ type: UPDATE_ACTOR_SUCCESS });
  }).catch(error => dispatch({ type: UPDATE_ACTOR_ERROR, error }));
}

export const deleteActor = (id, options = {}) => dispatch => {
  dispatch({ type: DELETE_ACTOR_REQUEST, id });
  return api.actors.del(id).then(res => {
    dispatch({ type: DELETE_ACTOR_SUCCESS });
  }).catch(error => {
    dispatch({ type: DELETE_ACTOR_ERROR, error })
  });
}



//###################### Actor Projects #######################

export const findActorProjects = (actorId, params = {}, options = {}) => dispatch => {
  const query = { page: 1, limit: 20, ...params };
  dispatch({ type: FIND_ACTOR_PROJECTS_REQUEST, actorId, query });
  return api.actors.findProjects(actorId, query, options).then(res => {
    const { projects } = res.data;
    dispatch({ type: FIND_ACTOR_PROJECTS_SUCCESS, projects });
    return projects;
  }).catch(error => dispatch({ type: FIND_ACTOR_PROJECTS_ERROR, error }));
}

//###################### Actor Members #######################

export const findActorMembers = (actorId, params = {}, options = {}) => dispatch => {
  const query = { page: 1, limit: 20, ...params };
  dispatch({ type: FIND_ACTOR_MEMBERS_REQUEST, actorId, query });
  return api.actors.findMembers(actorId, query, options).then(res => {
    const { members } = res.data;
    dispatch({ type: FIND_ACTOR_MEMBERS_SUCCESS, members });
    return members;
  }).catch(error => dispatch({ type: FIND_ACTOR_MEMBERS_ERROR, error }));
}

export const addActorMember = (actorId, data, options = {}) => dispatch => {
  dispatch({ type: ADD_ACTOR_MEMBER_REQUEST, actorId, data });
  return api.actors.addMember(actorId, data, options).then(res => {
    const { actor, member } = res.data;
    dispatch({ type: ADD_ACTOR_MEMBER_SUCCESS, actor, member });
    return { actor, member };
  }).catch(error => dispatch({ type: ADD_ACTOR_MEMBER_ERROR, error }));
}

export const removeActorMember = (actorId, userId, options = {}) => dispatch => {
  dispatch({ type: REMOVE_ACTOR_MEMBER_REQUEST, actorId, userId });
  return api.actors.removeMember(actorId, userId, options).then(res => {
    dispatch({ type: REMOVE_ACTOR_MEMBER_SUCCESS });
  }).catch(error => dispatch({ type: REMOVE_ACTOR_MEMBER_ERROR, error }));
}

//###################### Actor Articles #######################

export const findActorArticles = (actorId, params = {}, options = {}) => dispatch => {
  const query = { page: 1, limit: 20, ...params };
  dispatch({ type: FIND_ACTOR_ARTICLES_REQUEST, actorId, query });
  return api.actors.findArticles(actorId, query, options).then(res => {
    const { articles } = res.data;
    dispatch({ type: FIND_ACTOR_ARTICLES_SUCCESS, articles });
    return articles;
  }).catch(error => dispatch({ type: FIND_ACTOR_ARTICLES_ERROR, error }));
}

//###################### Actor Images #######################

export const findActorImages = (actorId, params = {}, options = {}) => dispatch => {
  const query = { page: 1, ...params };
  dispatch({ type: FIND_ACTOR_IMAGES_REQUEST, actorId, query });
  return api.actors.findImages(actorId, query, options).then(res => {
    const { images } = res.data;
    dispatch({ type: FIND_ACTOR_IMAGES_SUCCESS, images });
    return images;
  }).catch(error => dispatch({ type: FIND_ACTOR_IMAGES_ERROR, error }));
}

export const addActorImages = (actorId, data, options = {}) => dispatch => {
  dispatch({ type: ADD_ACTOR_IMAGES_REQUEST, actorId, data });
  return api.actors.addImages(actorId, data, options).then(res => {
    const { images } = res.data;
    dispatch({ type: ADD_ACTOR_IMAGES_SUCCESS, images });
    return { images };
  }).catch(error => dispatch({ type: ADD_ACTOR_IMAGES_ERROR, error }));
}

export const removeActorImage = (actorId, imageId, options = {}) => dispatch => {
  dispatch({ type: REMOVE_ACTOR_IMAGES_REQUEST, actorId, imageId });
  return api.actors.removeImage(actorId, imageId, options).then(res => {
    dispatch({ type: REMOVE_ACTOR_IMAGES_SUCCESS });
  }).catch(error => dispatch({ type: REMOVE_ACTOR_IMAGES_ERROR, error }));
}
