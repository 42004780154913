import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import DropzoneField from '@components/Field/DropzoneField';

class UploadImageForm extends PureComponent {
  onChange() {
    const { handleSubmit } = this.props;
    setTimeout(handleSubmit, 200);
  }

  render() {
    const { label, handleSubmit, multiple, children, ...props } = this.props;
    return (
      <div {...props}>
        <Field name="files" component={DropzoneField}
          label={ label }
          multiple={multiple === true}
          onChange={e => this.onChange(e)}
          >
          {children}
        </Field>
          
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  return errors;
}

export default reduxForm({
  form: 'upload-image',
  validate,
  initialValues: {
    files: [],
  },
})(UploadImageForm);