import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import ArticleForm from '../components/ArticleForm';
import { getArticle, updateArticle } from '../reducer';

class ArticleEditPage extends Component {
  componentDidMount() {
    const { getArticle, id } = this.props;
    getArticle(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) return getArticle(id);
  }

  onSubmit(values) {
    const { id, article, updateArticle, history } = this.props;
    updateArticle(id, {
      ...article,
      ...values,
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas: values.areas && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(res => {
      // history.push('/articles');
    })
  }

  render() {
    const { article } = this.props;
    return (
      <div>
        <Container>
          <h1>Edit Article</h1>
          {article && (
            <ArticleForm initialValues={{
              ...article,
              issues: article.issues && article.issues.reduce((result, issue) => ({ ...result, [issue]: true }), {}),
              areas: article.areas && article.areas.reduce((result, area) => ({ ...result, [area]: true }), {}),
            }} onSubmit={values => this.onSubmit(values)} />
          )}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.article.edit,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getArticle,
  updateArticle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditPage);