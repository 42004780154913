import React from 'react';
import queryString from 'query-string';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';



import { HomePage as AdminHomePage } from '@features/main';
import { ActorListPage as AdminActorListPage, ActorNewPage, ActorEditPage } from '@features/actors';
import { TagListPage } from '@features/tags';
import { ProjectListPage as AdminProjectListPage, ProjectNewPage, ProjectEditPage } from '@features/projects';
import { ArticleListPage, ArticleNewPage, ArticleEditPage } from '@features/articles';
import { NeedListPage, NeedNewPage, NeedEditPage } from '@features/needs';
import { ActorProfileAboutPage, ActorProfileProjectListPage, ActorProfileNeedListPage, ActorProfileMemberListPage, ActorProfileArticleListPage } from '@features/actors';
import { ProjectSettingsActorListPage, ProjectSettingsAboutPage, ProjectSettingsArticleListPage, ProjectSettingsNeedListPage } from '@features/projects';

export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/actors/new`} component={props => (<ActorNewPage {...props} />)} />
      <Route exact path={`${path}/actors/:id/about`} component={({ match, ...props }) => (<ActorProfileAboutPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/actors/:id/projects`} component={({ match, ...props }) => (<ActorProfileProjectListPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/actors/:id/members`} component={({ match, ...props }) => (<ActorProfileMemberListPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/actors/:id/articles`} component={({ match, ...props }) => (<ActorProfileArticleListPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/actors/:id/needs`} component={({ match, ...props }) => (<ActorProfileNeedListPage id={match.params.id} {...props} />)}></Route>
      <Route path={`${path}/actors/:id`} component={({ match, ...props }) => (<ActorEditPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/actors`} component={props => (<AdminActorListPage {...props} />)} />

      <Route exact path={`${path}/projects/new`} component={props => <ProjectNewPage {...props} />} />
      <Route exact path={`${path}/projects/:id/settings(/about)?`} component={({ match, ...props }) => (<ProjectSettingsAboutPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/projects/:id/settings/actors`} component={({ match, ...props }) => (<ProjectSettingsActorListPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/projects/:id/settings/articles`} component={({ match, ...props }) => (<ProjectSettingsArticleListPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/projects/:id/settings/needs`} component={({ match, ...props }) => (<ProjectSettingsNeedListPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/projects/:id`} component={({ match, ...props }) => (<ProjectEditPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/projects`} component={props => <AdminProjectListPage {...props} />} />

      <Route exact path={`${path}/articles/new`} component={props => <ArticleNewPage {...props} queries={queryString.parse(props.location.search)} />} />
      <Route exact path={`${path}/articles/:id`} component={({ match, ...props }) => (<ArticleEditPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/articles`} component={props => <ArticleListPage {...props} />} />

      <Route exact path={`${path}/needs/new`} component={props => <NeedNewPage {...props} queries={queryString.parse(props.location.search)} />} />
      <Route exact path={`${path}/needs/:id`} component={({ match, ...props }) => (<NeedEditPage id={match.params.id} {...props} />)}></Route>
      <Route exact path={`${path}/needs`} component={props => <NeedListPage {...props} />} />

      <Route exact path={`${path}/tags`} component={props => (<TagListPage {...props} />)} />

      <Route path={`${path}`} component={props => <AdminHomePage {...props} />} />
    </Switch>
  );
};