import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '@features/home/components/Layout';
import { findActors, ActorTypes } from '@features/actors/reducer';
import { issues as ProjectIssues } from '@features/projects/reducer';

import ActorCard from '@features/actors/components/ActorCard';
import ActorFilterForm from '@features/actors/components/ActorFilterForm';

import './ActorListPage.scss'

class ActorListPage extends PureComponent {
  state = {
    actors: [],
    initialFilters: {
      types: Object.keys(ActorTypes).reduce((result, key) => ({...result, [key]: true }), { all: true }),
      issues: ProjectIssues.reduce((result, issue) => ({...result, [issue.text]: true }), { all: true }),  
    },
  }

  async componentDidMount() {
    const { findActors } = this.props;
    const actors = await findActors();
    this.setState({
      actors,
      initialFilters: {
        types: Object.keys(ActorTypes).reduce((result, key) => ({...result, [key]: true }), { all: true }),
        issues: ProjectIssues.reduce((result, issue) => ({...result, [issue.text]: true }), { all: true }),  
      },  
    });
  }

  onFilterSubmit = async filter => {
    const { findActors } = this.props;
    this.setState({ actors: [] });
    const actors = await findActors({ ...filter });
    if (actors) this.setState({ actors });
  }

  render() {
    const { actors, initialFilters } = this.state;
    return (
      <Layout className="actor-list-page">
        <Container className="main-container">
          <h1 className="page-title">รวมคน</h1>
          <Row>
            <Col md={4}>
              <div>พื้นที่รวบรวมกลุ่มคน active citizen ที่สนใจและขับเคลื่อนการพัฒนาชุมชนย่านฝั่งธนบุรี</div>
              <ActorFilterForm
                initialValues={initialFilters}
                onSubmit={this.onFilterSubmit}
              />
            </Col>
            <Col md={8}>
              <Row className="actor-list">
                { actors && actors.map(actor => (
                  <Col md={6} key={actor.id}>
                    <ActorCard actor={actor} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  findActors,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorListPage);