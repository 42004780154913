import api from '@app/api';

export const FIND_ARTICLES_REQUEST  = 'action.articles.find.request';
export const FIND_ARTICLES_SUCCESS  = 'action.articles.find.success';
export const FIND_ARTICLES_ERROR    = 'action.articles.find.error';

export const GET_ARTICLE_REQUEST    = 'action.articles.get.request';
export const GET_ARTICLE_SUCCESS    = 'action.articles.get.success';
export const GET_ARTICLE_ERROR      = 'action.articles.get.error';

export const CREATE_ARTICLE_REQUEST = 'action.articles.create.request';
export const CREATE_ARTICLE_SUCCESS = 'action.articles.create.success';
export const CREATE_ARTICLE_ERROR   = 'action.articles.create.error';

export const UPDATE_ARTICLE_REQUEST = 'action.articles.update.request';
export const UPDATE_ARTICLE_SUCCESS = 'action.articles.update.success';
export const UPDATE_ARTICLE_ERROR   = 'action.articles.update.error';

export const DELETE_ARTICLE_REQUEST = 'action.articles.delete.request';
export const DELETE_ARTICLE_SUCCESS = 'action.articles.delete.success';
export const DELETE_ARTICLE_ERROR   = 'action.articles.delete.error';

const initialState = {
  list: {
    articles: [],
    loading: false,
    error: null,
  },
  new: {
    loading: false,
    error: null,
  },
  edit: {
    article: null,
    loading: false,
    error: null,
  }
};


// Reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case FIND_ARTICLES_REQUEST:
      return { ...state, list: { ...state.list,
        loading: true,
        error: null,
      }};

    case FIND_ARTICLES_SUCCESS:
      return { ...state, list: { ...state.list,
        articles: action.articles,
        loading: false,
        error: null,
      }};

    case FIND_ARTICLES_ERROR:
      return { ...state, list: { ...state.list,
        articles: [],
        loading: false,
        error: action.error,
      }};

    case GET_ARTICLE_REQUEST:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          article: null,
          loading: true,
          error: null,
        }};
      }
      break;

    case GET_ARTICLE_SUCCESS:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          article: action.article,
          loading: false,
          error: null,
        }};
      }
      break;

    case GET_ARTICLE_ERROR:
      if (action.mode === 'edit') {
        return { ...state, edit: {...state.edit,
          article: null,
          loading: false,
          error: action.error,
        }};
      }
      break;
              
    default: break;
  }
  return state;
}

// Actions
export const findArticles = (params = {}, options = {}) => dispatch => {
  const query = { page: 1, limit: 20, ...params };
  dispatch({ type: FIND_ARTICLES_REQUEST, query });
  return api.articles.find(query, options).then(res => {
    const { articles } = res.data;
    dispatch({ type: FIND_ARTICLES_SUCCESS, articles });
    return articles;
  }).catch(error => dispatch({ type: FIND_ARTICLES_ERROR, error }));
}

export const createArticle = (data, options = {}) => dispatch => {
  dispatch({ type: CREATE_ARTICLE_REQUEST, data });
  return api.articles.create(data, options).then(res => {
    const { article } = res.data;
    dispatch({ type: CREATE_ARTICLE_SUCCESS, article });
    return article;
  }).catch(error => dispatch({ type: CREATE_ARTICLE_ERROR, error }));
}

export const getArticle = (id, options = {}) => dispatch => {
  const { mode = 'edit' } = options;
  dispatch({ type: GET_ARTICLE_REQUEST, id, mode });
  return api.articles.get(id, options).then(res => {
    const { article } = res.data;
    dispatch({ type: GET_ARTICLE_SUCCESS, article, mode });
    return article;
  }).catch(error => dispatch({ type: GET_ARTICLE_ERROR, error, mode }));
}

export const updateArticle = (id, data, options = {}) => dispatch => {
  dispatch({ type: UPDATE_ARTICLE_REQUEST, id, data });
  return api.articles.update(id, data, options).then(res => {
    dispatch({ type: UPDATE_ARTICLE_SUCCESS });
  }).catch(error => dispatch({ type: UPDATE_ARTICLE_ERROR, error }));
}

export const deleteArticle = (id, options = {}) => dispatch => {
  dispatch({ type: DELETE_ARTICLE_REQUEST, id });
  return api.articles.del(id).then(res => {
    dispatch({ type: DELETE_ARTICLE_SUCCESS });
  }).catch(error => {
    dispatch({ type: DELETE_ARTICLE_ERROR, error })
  });
}