import React, { PureComponent } from 'react';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';

class CheckboxField extends PureComponent {
  render() {
    const { input, label, required, className, meta: { touched, error, warning} } = this.props;
    return (
      <Form.Check className={classnames(className, {
          required: required,
        })}>

        <label className="form-check-label">
          <input className="form-check-input" {...input} checked={input.value} type="checkbox" /> {label}
        </label>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </Form.Check>
    )
  }
}

export default CheckboxField;