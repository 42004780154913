import api from '@app/api';

export const FILES_UPLOAD_REQUEST  = 'action.files.upload.request';
export const FILES_UPLOAD_SUCCESS  = 'action.files.upload.success';
export const FILES_UPLOAD_ERROR    = 'action.files.upload.error';

const initialState = {
  files: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  return state;
}

export const upload = form => dispatch => {
  dispatch({ type: FILES_UPLOAD_REQUEST, form });

  const { files } = form;

  return api.files.upload(files).then(res => {
    dispatch({ type: FILES_UPLOAD_SUCCESS, files: res.data.files });
    return res.data.files;
  }).catch(error => {
    dispatch({ type: FILES_UPLOAD_ERROR, error });
  })
}

export const uploadImages = form => dispatch => {
  dispatch({ type: FILES_UPLOAD_REQUEST, form });

  const { files } = form;

  return api.images.upload(files).then(res => {
    dispatch({ type: FILES_UPLOAD_SUCCESS, images: res.data.images });
    return res.data.images;
  }).catch(error => {
    console.error(error);
    dispatch({ type: FILES_UPLOAD_ERROR, error });
  })
}
