import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '@features/home/components/Layout';
import ActorPageView from '@features/home/actors/ActorPageView';
import ActorEditPage from '@features/home/actors/ActorEditPage';
import ActorEditForm from '@features/home/actors/ActorEditForm';
import './ActorPage.scss';

import { getActor, findActorProjects, ActorTypes, findActorImages, updateActor } from '@features/actors/reducer';

class ActorPage extends PureComponent {
  state = {
    actor: null,
  }

  async componentDidMount() {
    const { getActor, id } = this.props;
    const actor = await getActor(id);

    this.setState({ actor });
  }

  onUpdateActor = async data => {
    const { actor } = this.state;
    const { updateActor, history } = this.props;
    data = {
      ...data,
      fields: ['name', 'description', 'thumbnail', 'logo', 'latlng', 'type', 'skills', 'issues', 'tel', 'email', 'lineId', 'facebook'],
    }
    await updateActor(actor.id, data, { mode: 'partial' }).then(() => {
      this.setState({
        actor: {
          ...actor,
          ...data,
        }
      });
    });
    history.push(`/actors/${actor.id}/${actor.name}`);
    
  }

  render() {
    const { currentUser, id, mode } = this.props;
    const { actor } = this.state;
    return (
      <div className="actor-page">
        { currentUser && mode === 'edit' ? actor && (
            <ActorEditForm id={id} initialValues={{ ...actor }} onSubmit={data => this.onUpdateActor(data)} />
        ) : (
          <ActorPageView id={id} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  updateActor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorPage);