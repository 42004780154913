import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button, Row, Col } from 'react-bootstrap';

import './RegisterForm.scss';

class RegisterForm extends PureComponent {

  onSubmit(data) {
    const { handleSubmit } = this.props;
    handleSubmit(data);
  }

  render() {
    return (
      <Form onSubmit={data => this.onSubmit(data)} className="register-form">
        <h1>สมัครสมาชิก</h1>

        <Form.Group>
          <Form.Label>อีเมล</Form.Label>
          <Field
            name="email"
            className="form-control"
            component="input"
            type="email"
          />
        </Form.Group>

        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>รหัสผ่าน</Form.Label>
              <Field
                name="password"
                className="form-control"
                component="input"
                type="password"
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
              <Field
                name="password2"
                className="form-control"
                component="input"
                type="password"
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>หมายเลขโทรศัพท์</Form.Label>
          <Field
            name="tel"
            className="form-control"
            component="input"
            type="text"
          />
        </Form.Group>

        <Button type="submit" variant="primary" block ><i className="icon icon-rocket"></i> สมัครเป็นคนธน</Button>
      </Form>
    )
  }
}
export default compose(
  reduxForm({
    form: 'register',
  }),
)(RegisterForm);