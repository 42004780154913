import React, { PureComponent, Fragment, useState } from 'react';
import moment from 'moment-with-locales-es6';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '@features/home/components/Layout';
import ActorNewForm from '@features/actors/components/ActorNewForm';
import { createActor, removeActorMember, ActorTypes } from '@features/actors/reducer';
import { reloadAccount } from '@features/auth/reducer';

import './ProfileActorListPage.scss';

class ProfileActorListPage extends PureComponent {
  state = {
    show: false,
  }

  async onCreateActor(data) {
    const { createActor, reloadAccount, history } = this.props;
    createActor(data).then(async actor => {
      await reloadAccount();
      history.push(`/actors/${actor.id}/${actor.name}/edit`);
    });
  }

  onRemoveActor(actorId) {
    const { currentUser, removeActorMember, reloadAccount } = this.props;
    removeActorMember(actorId, currentUser.id).then(() => reloadAccount());
  }

  onOfflineActor(actorId) {

  }

  setModal(show) {
    this.setState({ show });
  }
  
  render() {
    const { currentUser } = this.props;
    const { show } = this.state;
    const actors = currentUser? currentUser.actors: [];

    const mainTopbar = currentUser && (
      <Container className="profile-actor-topbar">
        <Row>
          <Col>
            <div>{currentUser.email}</div>
            <div className="font-header">คน</div>
          </Col>
          <Col className="col-right">
            <Button className="new-actor-btn font-header" onClick={() => this.setModal(true)}>สร้างคนใหม่</Button>
          </Col>
        </Row>
      </Container>
    );

    return (
      <Layout className="profile-actor-list-page" hideFooter={true} hideMainTopbar={true}>
        { currentUser && (
          <Fragment>
            {mainTopbar}
            <Container>
              <h3 className="page-title">คน</h3>
              <div className="actor-list">
                {actors && actors.length > 0? actors.map(actor => {
                  const color = ActorTypes[actor.type] && ActorTypes[actor.type].color;
                  return (
                    <Row key={actor.id} className="actor">
                      <Col className="actor-info">
                        <h4><Link className="actor-name" to={`/actors/${actor.id}/${actor.name}/edit`} style={{ color }}>{actor.name}</Link></h4>
                        <div className="actor-meta">เข้าร่วม {moment(actor.createdAt).format('DD MMM YYYY HH:mm')}</div>
                        <div className="actor-meta">แก้ไข {moment(actor.updatedAt).format('DD MMM YYYY HH:mm')}</div>
                      </Col>
                      <Col className="actor-action-bar col-right">
                        <div>
                          <div><Link to={`/profile/actors/${actor.id}/projects`}><Button className="project-btn font-header" style={{ backgroundColor: color }} block>ผลงาน</Button></Link></div>
                          <div className="space-between">
                            <span className="hide-btn font-header" onClick={e => this.onOfflineActor(actor.id)}><i className="icon icon-eye"></i> ซ่อน</span>
                            <span className="remove-btn font-header" onClick={e => this.onRemoveActor(actor.id)}><i className="icon icon-trash"></i> ลบ</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                }) : (
                  <div className="no-actor-panel">
                    <div>คุณยังไม่เคยบันทึกข้อมูลคนทำงานไว้ เริ่มสร้างข้อมูลของคุณโดยกดปุ่ม "สร้างคนใหม่"</div>
                    <Button className="new-actor-btn font-header" onClick={() => this.setModal(true)}>สร้างเลย!</Button>
                  </div>
                )}
              </div>

              
            </Container>
            <Modal show={show} onHide={() => this.setModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title className="font-header">สร้างคนใหม่</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ActorNewForm onSubmit={data => this.onCreateActor(data)} />
              </Modal.Body>
            </Modal>
        </Fragment>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  createActor,
  removeActorMember,
  reloadAccount,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActorListPage);