import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './ProjectSettingsLayout.scss';

class ProjectSettingsLayout extends PureComponent {
  render() {
    const { project, children } = this.props;
    
    return (
      <section>
      { project && (
        <Container className="project-settings-layout">
          <Row>
            <Col md={3} className="project-settings-sidebar">
              <h4>{project.name}</h4>
              
              <Nav defaultActiveKey="dashboard" className="flex-column">
                <Link className="nav-link" to={`/projects/${project.id}/settings/about`}>About</Link>
                <Link className="nav-link" to={`/projects/${project.id}/settings/actors`}>Actors</Link>
                <Link className="nav-link" to={`/projects/${project.id}/settings/articles`}>Articles</Link>
                <Link className="nav-link" to={`/projects/${project.id}/settings/needs`}>Needs</Link>
                <Dropdown.Divider />
                
                <Link className="nav-link text-danger" to={`/projects/${project.id}/delete`}>Delete</Link>
              </Nav>
            </Col>
            <Col md={9}>
              {children}
            </Col>
          </Row>
        </Container>
      )}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsLayout);