import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import ProjectCard from '@features/projects/components/ProjectCard';
import { getActor, findActorProjects, updateActor, findActorImages, addActorImages, removeActorImage } from '@features/actors/reducer';
import { createProject } from '@features/projects/reducer';
import { findTags } from '@features/tags/reducer';
import { uploadImages } from '@features/main/reducer';

import Layout from '@features/home/components/Layout';
import UploadImageForm from '@components/Form/UploadImageForm';
import EditableLatLngForm from '@components/Form/EditableLatLngForm';

import EditableTextForm from '@components/Form/EditableTextForm';
import EditableSelectForm from '@components/Form/EditableSelectForm';
import EditableTagsForm from '@components/Form/EditableTagsForm';
import EditableDraftForm from '@components/Form/EditableDraftForm';
import { ProjectNewForm } from '@features/projects';

import mockupThumbnail from '@assets/images/contents/mockup.jpg';
import addThumbnail from '@assets/images/add-thumbnail.png';

const actorTypes = {
  PERSON: 'บุคคลธรรมดา',
  PRIVATE_GROUP: 'ชุมชน,กลุ่มภาคประชาสังคม',
  PRIVATE_ORGANIZATION: 'สมาคม, มูลนิธิ, องค์กรสาธารประโยชน์ (Social enterprise)',
  PUBLIC_ORGANIZATIOn: 'บริษัท, ห้างหุ้นส่วน',
  EDUCATION_INSTITUTION: 'สถาบันการศึกษาทั้งของรัฐและเอกชน',
  GOVERNMENT: 'หน่วยงานภาครัฐบาล',
};


const gallery = [
  { url: mockupThumbnail, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit" },
  { url: mockupThumbnail, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit" },
  { url: mockupThumbnail, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit" },
  { url: mockupThumbnail, caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit" },
]

class ActorEditPage extends PureComponent {
  state = {
    actor: null,
    projects: [],
    allSkills: [],
    allIssues: [],
    images: [],
  }

  async componentDidMount() {
    const { getActor, findActorProjects, findTags, findActorImages, id } = this.props;
    const actor = await getActor(id);
    const projects = await findActorProjects(id);
    const allSkills = await findTags({ type: 'skill' });
    const allIssues = await findTags({ type: 'issue' });
    const images = await findActorImages(id);
    this.setState({
      actor,
      projects,
      allSkills,
      allIssues,
      images,
    });
  }

  onUploadLogo = async (data) => {
    const { uploadImages } = this.props;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      const logo = images[0];
      this.onEditableSubmit(['logo'], { logo: logo.url });
    }
  }


  onUploadThumbnail = async (data) => {
    const { uploadImages } = this.props;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      const thumbnail = images[0];
      this.onEditableSubmit(['thumbnail'], { thumbnail: thumbnail.url });
    }
  }

  onUploadGallery = async (data) => {
    const { id, uploadImages, findActorImages, addActorImages } = this.props;
    const images = await uploadImages(data);
    if (images && images.length > 0) {
      await addActorImages(id, { imageIds: images.map(image => image.id) });
      this.setState({ images: await findActorImages(id) });
    }
  }

  onRemoveGallery = async (imageId) => {
    const { id, removeActorImage, findActorImages } = this.props;
    await removeActorImage(id, imageId);
    this.setState({ images: await findActorImages(id) });
  }

  onEditableSubmit = (fields, values) => {
    const { updateActor } = this.props;
    const { actor } = this.state;
    const data = {
      fields,
      ...values,
    };

    updateActor(actor.id, data, { mode: 'partial' }).then(() => {
      this.setState({
        actor: {
          ...actor,
          ...values,
        }
      });
  
    })
  }

  onCreateProject(data) {
    const { id, createProject, history } = this.props;
    createProject({
      ...data,
      actorId: id, 
    }).then(project => {
      window.location.href = `/projects/${project.id}/${project.name}`;
    });
  }


  render() {
    const { actor, projects, allSkills, allIssues, images, } = this.state;
    return actor && (
      <Layout className="actor-page view">
        <Row className="cover-section">
          <Col md={7}>
            <UploadImageForm name="thumbnail" onSubmit={this.onUploadThumbnail}>
              <img src={actor.thumbnail || addThumbnail} width="100%" alt="" />
            </UploadImageForm>
          </Col>
          <Col md={5}>
            <div className="actor-header">
              <UploadImageForm className="actor-logo-form" name="logo" onSubmit={this.onUploadLogo}>
                <img className="actor-logo" src={actor.logo || addThumbnail} alt={actor.name} />
              </UploadImageForm>
              <h1 className="actor-name">
                <EditableTextForm
                  form="actor-name"
                  name="name"
                  data={actor.name}
                  onSubmit={data => this.onEditableSubmit(['name'], { name: data.name })}
                  initialValues={actor}
                  />
              </h1>
              <div className="actor-meta">
                { projects.length } งาน  | เข้าร่วมเมื่อ 11 มกราคม 2563
              </div>
            </div>
            <div className="about">
              <EditableDraftForm
                    form="actor-description"
                    name="description"
                    data={actor.description}
                    onSubmit={data => this.onEditableSubmit(['description'], { description: data.description })}
                    initialValues={actor}
                  />
            </div>
          </Col>
        </Row>

        <Container className="project-list-section">
          <div className="section-header">
            <h4 className="section-title">ผลงาน</h4>
          </div>
          <Row className="project-list">
            { projects && projects.map(project => (
              <Col md={4} key={project.id}>
                <ProjectCard project={project} />
              </Col>
            ))}
            <Col md={4} key={'new'}>
              <h4>เพิ่มผลงาน</h4>
              <ProjectNewForm onSubmit={data => this.onCreateProject(data)} />
            </Col>
          </Row>
        </Container>

        <Container className="info-section">
          <Row className="info latlng">
            <Col md={4}><h4 className="info-title">ตำแหน่งที่ตั้ง</h4></Col>
            <Col md={8}>
              <EditableLatLngForm
                form="actor-latlng"
                name="latlng"
                onSubmit={data => this.onEditableSubmit(['latlng'], { latlng: data.latlng })}
                initialValues={actor}
              />
            </Col>
          </Row>

          <Row className="info type">
            <Col md={4}><h4 className="info-title">ประเภทกลุ่ม</h4></Col>
            <Col md={8}>
              <EditableSelectForm
                  form="actor-type"
                  name="type"
                  options={Object.keys(actorTypes).map(key => ({ value: key, text: actorTypes[key] }))}
                  data={actorTypes[actor.type] || actor.type}
                  onSubmit={data => this.onEditableSubmit(['type'], { type: data.type })}
                  initialValues={actor}
                />
            </Col>
          </Row>
          <Row className="info skill-list">
            <Col md={4}><h4 className="info-title">ทักษะและงานที่ทำ</h4></Col>
            <Col md={8}>
              <EditableTagsForm
                form="actor-skills"
                name="skills"
                allTags={allSkills}
                tags={actor.skills}
                initialValues={actor}
                onSubmit={data => this.onEditableSubmit(['skills'], { skills: data.skills })}
                />
            </Col>
          </Row>
          <Row className="info issue-list">
            <Col md={4}><h4 className="info-title">ความสนใจ</h4></Col>
            <Col md={8}>
              <EditableTagsForm
                form="actor-issues"
                name="issues"
                allTags={allIssues}
                tags={actor.issues}
                initialValues={actor}
                onSubmit={data => this.onEditableSubmit(['issues'], { issues: data.issues })}
                />
            </Col>
          </Row>
          <Row className="info contact">
            <Col md={4}><h4 className="info-title">ติดต่อ</h4></Col>
            <Col md={8}>
              <Row>
                <Col md={3}>โทรศัพท์</Col>
                <Col>
                  <EditableTextForm
                    form="actor-tel"
                    name="tel"
                    data={actor.tel}
                    onSubmit={data => this.onEditableSubmit(['tel'], { tel: data.tel })}
                    initialValues={actor}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>E-mail</Col>
                <Col>
                  <EditableTextForm
                    form="actor-email"
                    name="email"
                    data={actor.email}
                    onSubmit={data => this.onEditableSubmit(['email'], { email: data.email })}
                    initialValues={actor}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>Line</Col>
                <Col>
                  <EditableTextForm
                    form="actor-lineId"
                    name="lineId"
                    data={actor.lineId}
                    onSubmit={data => this.onEditableSubmit(['lineId'], { lineId: data.lineId })}
                    initialValues={actor}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>Facebook</Col>
                <Col>
                  <EditableTextForm
                    form="actor-facebook"
                    name="facebook"
                    data={actor.facebook}
                    onSubmit={data => this.onEditableSubmit(['facebook'], { facebook: data.facebook })}
                    initialValues={actor}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container className="gallery-section">
          <div className="section-header">
            <h4 className="section-title">รูปภาพ</h4>
          </div>
          <Row>
            { images && images.map((image, i) => (
              <Col md={6} key={i}>
                <figure className="figure">
                  <img className="figure-img" width="100%" src={image.url} alt={image.description} />
                  <figcaption className="figure-caption">{image.description}</figcaption>
                  <Button className="btn btn-danger" onClick={() => this.onRemoveGallery(image.id)}>Remove</Button>
                </figure>
              </Col>
            ))}
            <Col md={6}>
              <UploadImageForm
                name="images"
                multiple={true}
                onSubmit={this.onUploadGallery}
              >
                <img src={addThumbnail} width="100%" alt="" />
              </UploadImageForm>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  findActorProjects,
  findTags,
  updateActor,
  createProject,
  uploadImages,
  findActorImages,
  addActorImages,
  removeActorImage,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ActorEditPage);