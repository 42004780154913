import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col, Button } from 'react-bootstrap';

import ActorProfileLayout from '../containers/ActorProfileLayout';
import { getActor, findActorMembers, addActorMember, removeActorMember } from '../reducer';
import { UserInviteForm } from '@features/users';

class ActorProfileProjectListPage extends PureComponent {

  state = {
    actor: null,
    members: null,
  }

  async init() {
    const { id, getActor, findActorMembers } = this.props;
    const actor = await getActor(id);
    const members = await findActorMembers(id);
    this.setState({ actor, members })
  }

  componentDidMount() {
    this.init();
  }

  onUserInvite(data) {
    const { id, addActorMember } = this.props;
    addActorMember(id, data).then(() => this.init());
  }

  onUserRemove(userId) {
    const { id, removeActorMember } = this.props;
    removeActorMember(id, userId).then(() => this.init());
  }

  render() {
    const { actor, members } = this.state;
    return (
      <Fragment>
        {actor && (
          <ActorProfileLayout actor={actor}>
            <h3>Members</h3>

            {members && members.map(user => (
              <Row>
                <Col>{user.email}</Col>
                <Col><Button variant="danger" onClick={e => this.onUserRemove(user.id)}>Remove</Button></Col>
              </Row>
            ))}

            <UserInviteForm onSubmit={data => this.onUserInvite(data)} />

          </ActorProfileLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  findActorMembers,
  addActorMember,
  removeActorMember,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorProfileProjectListPage);