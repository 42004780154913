import * as base from './_base';
import { endpoint } from '@config/api';

export default {
  ...base.generateCRUD('projects'),
  
  count: () => {
    const url = `${endpoint}/projects/count`;
    return base.get(url);
  },

  findActors: (projectId, query, options) => {
    const url = `${endpoint}/projects/${projectId}/actors`;
    return base.get(url, query, options);
  },
  addActor: (projectId, data, options) => {
    const url = `${endpoint}/projects/${projectId}/actors`;
    return base.post(url, data, options);    
  },
  removeActor: (projectId, actorId, options) => {
    const url = `${endpoint}/projects/${projectId}/actors/${actorId}`;
    return base.del(url, options);
  },

  findImages: (projectId, query, options) => {
    const url = `${endpoint}/projects/${projectId}/images`;
    return base.get(url, query, options);
  },

  addImages: (projectId, data, options) => {
    const url = `${endpoint}/projects/${projectId}/images`;
    return base.post(url, data, options);
  },

  removeImage: (projectId, imageId, options) => {
    const url = `${endpoint}/projects/${projectId}/images/${imageId}`;
    return base.del(url, options);
  },

  findArticles: (projectId, query, options) => {
    const url = `${endpoint}/projects/${projectId}/articles`;
    return base.get(url, query, options);
  },

  findNeeds: (projectId, query, options) => {
    const url = `${endpoint}/projects/${projectId}/needs`;
    return base.get(url, query, options);
  },

}