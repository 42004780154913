import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import NeedForm from '../components/NeedForm';
import { getNeed, updateNeed } from '../reducer';

class NeedEditPage extends Component {
  componentDidMount() {
    const { getNeed, id } = this.props;
    getNeed(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) return getNeed(id);
  }

  onSubmit(values) {
    const { id, need, updateNeed, history } = this.props;
    updateNeed(id, {
      ...need,
      ...values,
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas: values.areas && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(res => {
      // history.push('/needs');
    })
  }

  render() {
    const { need } = this.props;
    return (
      <div>
        <Container>
          <h1>Edit Need</h1>
          {need && (
            <NeedForm initialValues={{
              ...need,
              issues: need.issues && need.issues.reduce((result, issue) => ({ ...result, [issue]: true }), {}),
              areas: need.areas && need.areas.reduce((result, area) => ({ ...result, [area]: true }), {}),
            }} onSubmit={values => this.onSubmit(values)} />
          )}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.need.edit,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getNeed,
  updateNeed,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NeedEditPage);