import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import ActorProfileLayout from '../containers/ActorProfileLayout';
import { getActor, findActorArticles } from '../reducer';
import { deleteArticle } from '@features/articles/reducer';

class ActorProfileArticleListPage extends PureComponent {

  state = {
    actor: null,
    articles: null,
  }

  async init() {
    const { id, getActor, findActorArticles } = this.props;
    const actor = await getActor(id);
    const articles = await findActorArticles(id);
    this.setState({ actor, articles })
  }

  componentDidMount() {
    this.init();
  }

  onRemoveArticle(articleId) {
    const { deleteArticle } = this.props;
    deleteArticle(articleId).then(() => this.init());
  }

  render() {
    const { actor, articles } = this.state;
    return (
      <Fragment>
        {actor && (
          <ActorProfileLayout actor={actor}>
            <h3>Articles</h3>

            {articles && articles.map(article => (
              <Row>
                <Col><Link to={`/articles/${article.id}`}>{article.name}</Link></Col>
                <Col><Button variant="danger" onClick={e => this.onRemoveArticle(article.id)}>Remove</Button></Col>
              </Row>
            ))}

            <Link to={`/articles/new?actorId=${actor.id}`} className="btn btn-primary">New artcile</Link>
          </ActorProfileLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  findActorArticles,
  deleteArticle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorProfileArticleListPage);