import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Layout from '@features/home/components/Layout';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LoginForm from '../components/LoginForm';
import { login } from '../reducer';

import './LoginPage.scss';

class LoginPage extends PureComponent {

  onSubmit(data) {
    const { login, history } = this.props;
    login(data).then(user => {
      history.push('/profile/actors');
    }).catch(error => {
      
    });
  }

  render() {
    return (
      <Layout className="login-page" hideFooter={true}>
        <Container>
          <LoginForm onSubmit={data => this.onSubmit(data)} />
          <div className="login-footer">
            ยังไม่เป็นสมาชิกคนธนใช่หรือไม่<br />
            ร่วมเป็นเครือข่ายคนธนได้ <Link to="/auth/register">ที่นี่</Link>
          </div>
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);