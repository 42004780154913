import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { Container, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { loadCurrentUser, logout } from '@features/auth/reducer';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@styles/form.scss';
import './Layout.scss';

class Layout extends PureComponent {

  componentDidMount() {
    const { loadCurrentUser } = this.props;
    loadCurrentUser();
  }

  onLogout() {
    const { logout } = this.props;
    logout().then(() => {
      window.location.href = '/';
    });
  }

  render() {
    const { children, currentUser } = this.props;
    return (
      <div id="wrapper">
        <Navbar bg="light" expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">YoungThon</Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Link to="/actors" className="nav-link">Actors</Link>
                <Link to="/projects" className="nav-link">Projects</Link>
                <Link to="/articles" className="nav-link">Articles</Link>
                <Link to="/needs" className="nav-link">Needs</Link>
                <Link to="/tags" className="nav-link">Tags</Link>
              </Nav>
              <Nav>
                {currentUser? (
                  <Fragment>
                    <NavDropdown title="Actors">
                      {currentUser.actors && currentUser.actors.map(actor => (
                        <Link className="dropdown-item" to={`/actors/${actor.id}/about`}>{actor.name}</Link>
                      ))}
                      
                      <Link to="/profile/actors" className="dropdown-item">Manage Actors</Link>
                    </NavDropdown>
                    <NavDropdown title={currentUser.email}>
                      <Link to="/profile/settings" className="dropdown-item">Settings</Link>
                      <div className="dropdown-item" onClick={() => this.onLogout()}>Log out</div>
                    </NavDropdown>
                  </Fragment>
                ): (
                  <Fragment>
                    <Link to="/auth/register" className="nav-link">Register</Link>
                    <Link to="/auth/login" className="nav-link">Login</Link>
                  </Fragment>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div id="main">
          {children}
        </div>
        {/* <div id="footer">
          <Container>
            Footer
          </Container>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadCurrentUser,
  logout,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Layout);