import axios from 'axios';
import { endpoint } from '@config/api';

const getHeaders = (data = {}, options = {}) => {
  return {
    'x-access-token': localStorage.getItem('accessToken'),
    ...data,
  };
}

export const get = (url, query = {}, options = {}) => {
  const queryString = (query && Object.keys(query).length > 0)? '?' + Object.keys(query).map(name => `${name}=${query[name]}`).join('&'):'';
  options = {
    ...options,
    headers: getHeaders(options.headers),
  };
  return axios.get(`${url}${queryString}`, options).then(res => {
    if (res.status === 'error') throw res.error;
    return res;
  });;
};

export const post = (url, data, options = {}) => {
  options = {
    ...options,
    headers: getHeaders(options.headers),
  };
  return axios.post(url, data, options).then(res => {
    if (res.status === 'error') throw res.error;
    return res;
  });
};

export const put = (url, data, options = {}) => {
  options = {
    ...options,
    headers: getHeaders(options.headers),
  };
  return axios.put(url, data, options).then(res => {
    if (res.status === 'error') throw res.error;
    return res;
  });
};

export const del = (url, options = {}) => {
  options = {
    ...options,
    headers: getHeaders(options.headers),
  };
  return axios.delete(url, options).then(res => {
    if (res.status === 'error') throw res.error;
    return res;
  });
};

export const upload = (url, files, options = {}) => {
  const { name = 'files', ...requestOptions } = options;
  const formData = new FormData();
  files.map(file => formData.append(name, file));

  return axios.post(url, formData, {
    ...requestOptions,
    headers: getHeaders(requestOptions.headers),
  });
};

export const generateCRUD = (name) => ({
  find: (query = {}, options) => {
    const url = `${endpoint}/${name}`;
    return get(url, query, options);
  },
  
  get: (id, query = {}, options) => {
    const url = `${endpoint}/${name}/${id}`;
    return get(url, query, options);
  },
  
  create: (data, options) => {
    const url = `${endpoint}/${name}`;
    return post(url, data, options);
  },
  
  update: (id, data, options) => {
    const url = `${endpoint}/${name}/${id}`;
    return put(url, data, options);
  },
  
  del: (id, options) => {
    const url = `${endpoint}/${name}/${id}`;
    return del(url, options);
  }
});