import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { DateRangePicker } from 'react-dates';
import _ from 'lodash';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const dateFormat = 'YYYY-MM-DD';

class DateRangePickerFields extends PureComponent {
  state = {
    focusedInput: false,
    startDateError: null,
    endDateError: null,
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
    const { startDateId, endDateId } = this.props;
    const startDate = this.props[startDateId];
    const endDate = this.props[endDateId];
    startDate.input.onFocus();
    endDate.input.onFocus();
  }

  onClose = () => {
    const { startDateId, endDateId } = this.props;
    const startDate = this.props[startDateId];
    const endDate = this.props[endDateId];
    startDate.input.onBlur();
    endDate.input.onBlur();
  }

  onChange(val) {
    const { startDateId, endDateId } = this.props;
    const startDate = this.props[startDateId];
    const endDate = this.props[endDateId];

    if (startDate && startDate.input) {
      startDate.input.onChange(val.startDate && val.startDate.format(dateFormat));
      if (val.startDate) this.setState({ startDateError: null });
    }
    if (endDate && endDate.input) {
      endDate.input.onChange(val.endDate && val.endDate.format(dateFormat));
      if (val.endDate) this.setState({ endDateError: null });
    }
  }

  handleOutsideRange = day => {
    const { handleOutsideRange } = this.props;
    if (_.isFunction(handleOutsideRange)) {
      return handleOutsideRange(day, this.state.focusedInput);
    }
    return false;
  }

  render() {
    const { focusedInput } = this.state;
    const { names, className, startDateId, endDateId, ...props } = this.props;
    const startDate = this.props[startDateId];
    const endDate = this.props[endDateId];
    const touched = startDate.meta.touched || endDate.meta.touched;

    return (
      
      <div className={classNames(`date-range-picker ${className}`, touched && 'error')}>
        <DateRangePicker
          {...props}
          displayFormat={this.props.displayFormat}
          onDatesChange={val => this.onChange(val)}
          onFocusChange={val => this.onFocusChange(val)}
          focusedInput={focusedInput}
          startDateId={startDateId}
          endDateId={endDateId}
          startDate={startDate.input.value && moment(startDate.input.value)}
          endDate={endDate.input.value && moment(endDate.input.value)}
          isOutsideRange={this.handleOutsideRange}
          onClose={this.onClose}
        />
      </div>
    );
  }
};

export default DateRangePickerFields;
