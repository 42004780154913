import React from 'react';
import queryString from 'query-string';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import store from '@config/store';
import { default as AdminRouter } from '@features/admin/router';
import { default as HomeRouter } from '@features/home/router';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/admin" component={props => (<AdminRouter {...props} />)} />
          <Route path="/" component={props => (<HomeRouter {...props} />)} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
