import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Container } from 'react-bootstrap';
import ArticleForm from '../components/ArticleForm';
import { createArticle } from '../reducer';

class ArticleNewPage extends PureComponent {

  onSubmit(values) {
    const { createArticle, history } = this.props;
    const { actorId, projectId } = this.props.queries;

    createArticle({
      ...values,
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas: values.areas && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(() => {
      if (projectId) history.push(`/projects/${projectId}/settings/articles`);
      else if(actorId) history.push(`/actors/${actorId}/articles`);
      else history.push('/articles');
    });
  }

  render() {
    const { actorId, projectId } = this.props.queries;

    return (
      <div>
        <Container>
          <section className="page-header">
            <h1>New Article</h1>
          </section>
          <section className="page-body">
            <ArticleForm onSubmit={values => this.onSubmit(values)} initialValues={{ actorId, projectId }} />
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  createArticle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ArticleNewPage);