import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'react-bootstrap';

class EditableTextForm extends PureComponent {
  state = {
    mode: 'view',
  }

  onSubmit = (data) => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit(data);
    this.setMode('view');
  }

  setMode = (mode) => {
    this.setState({ mode });
  }

  render() {
    const { name, data, placeholder, handleSubmit } = this.props;
    const { mode } = this.state;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={classnames('editable', mode)}>
          { mode === 'edit'? (
            <div className="form-inline">
              <Field name={name} component="input" className="form-control" type="text" placeholder={placeholder} />
              <Button type="submit">Save</Button>
            </div>
          ) : (
            <div>
              {data}
              <div className="btn btn-primary btn-sm" onClick={() => this.setMode('edit')}>Edit</div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default reduxForm()(EditableTextForm);
