import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileLayout from '../containers/ProfileLayout';

class ProfileDashboardPage extends PureComponent {
  render() {
    return (
      <ProfileLayout>
        <h3>Dashboard</h3>
      </ProfileLayout>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDashboardPage);