import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';

import { findArticles, deleteArticle } from '../reducer';

class ArticleListPage extends PureComponent {
  componentDidMount() {
    const { findArticles } = this.props;
    findArticles();
  }

  onDeleteArticle(id) {
    const { deleteArticle, findArticles } = this.props;
    deleteArticle(id).then(() => {
      findArticles();
    });
  }

  render() {
    const { articles } = this.props;
    return (
      <div>
        <Container>
          <section className="page-header">
            <h1 className="page-title">Article</h1>
            <ButtonToolbar className="justify-content-between">
              <ButtonGroup>
                <Link to="/articles/new"><Button variant="primary">New</Button></Link>
              </ButtonGroup>
              <ButtonGroup>
              </ButtonGroup>
            </ButtonToolbar>
          </section>
          <section className="page-body">
            <div className="article-list">
              {articles && articles.map(article => (
                <Row key={article.id}>
                  <Col><Link to={`/articles/${article.id}`}>{article.name}</Link></Col>
                  <Col>
                    <ButtonGroup>
                      <Button variant="danger" onClick={e => this.onDeleteArticle(article.id)}>Delete</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              ))}
            </div>
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.article.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  findArticles,
  deleteArticle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ArticleListPage);