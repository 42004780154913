import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import ProjectSettingsLayout from '../containers/ProjectSettingsLayout';
import { getProject, findProjectNeeds } from '../reducer';
import { deleteNeed } from '@features/needs/reducer';

class ProjectSettingsNeedListPage extends PureComponent {

  state = {
    project: null,
    needs: null,
  }

  async init() {
    const { id, getProject, findProjectNeeds } = this.props;
    const project = await getProject(id);
    const needs = await findProjectNeeds(id);
    this.setState({ project, needs });
  }

  componentDidMount() {
    this.init();
  }


  onRemoveNeed(needId) {
    const { deleteNeed } = this.props;
    deleteNeed(needId).then(() => this.init());
  }

  render() {
    const { project, needs } = this.state;
    return (
      <Fragment>
        {project && (
          <ProjectSettingsLayout project={project}>
            <h3>Needs</h3>

            {needs && needs.map(need => (
              <Row>
                <Col>{need.name}</Col>
                <Col><Button variant="danger" onClick={e => this.onRemoveNeed(need.id)}>Remove</Button></Col>
              </Row>
            ))}

            <Link to={`/needs/new?projectId=${project.id}`} className="btn btn-primary">New Need</Link>

          </ProjectSettingsLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  findProjectNeeds,
  deleteNeed,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsNeedListPage);