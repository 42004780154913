import React, { PureComponent, Fragment, useState } from 'react';
import moment from 'moment-with-locales-es6';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '@features/home/components/Layout';
import { ProjectNewForm } from '@features/projects';
import { getActor, findActorProjects } from '@features/actors/reducer';
import { createProject } from '@features/projects/reducer';

import './ProfileProjectListPage.scss';

class ProfileActorListPage extends PureComponent {
  state = {
    actor: null,
    projects: [],
    show: false,
  }

  async componentDidMount() {
    const { getActor, findActorProjects, id } = this.props;
    const actor = await getActor(id);
    const projects = await findActorProjects(id);

    this.setState({ actor, projects });
  }

  onCreateProject(data) {
    const { id, createProject, history } = this.props;
    createProject({
      ...data,
      actorId: id, 
    }).then(project => {
      window.location.href = `/projects/${project.id}/${project.name}/edit`;
    });
  }

  onRemoveProject(projectId) {
    const { currentUser, removeActorMember, reloadAccount } = this.props;
  }

  setModal(show) {
    this.setState({ show });
  }
  
  render() {
    const { currentUser } = this.props;
    const { actor, projects, show } = this.state;

    const mainTopbar = currentUser && actor && (
      <Container className="profile-project-topbar">
        <Row>
          <Col>
            <div>{currentUser.email}</div>
            <div className="font-header">คน > {actor.name} > ผลงาน</div>
          </Col>
          <Col className="col-right">
            <Button className="new-project-btn font-header" onClick={() => this.setModal(true)}>สร้างงานใหม่</Button>
          </Col>
        </Row>
      </Container>
    );

    return (
      <Layout className="profile-project-list-page" hideFooter={true} hideMainTopbar={true}>
        { currentUser && actor && (
          <Fragment>
            {mainTopbar}
            <Container>
              <h3 className="page-title">ผลงาน</h3>
              <div className="project-list">
                {projects && projects.length > 0? projects.map(project => {
                  return (
                    <Row key={actor.id} className="project">
                      <Col className="project-info">
                        <h4><Link className="project-name" to={`/projects/${project.id}/${project.name}/edit`}>{project.name}</Link></h4>
                        <div className="project-meta">สร้าง {moment(project.createdAt).format('DD MMM YYYY HH:mm')}</div>
                        <div className="project-meta">แก้ไข {moment(project.updatedAt).format('DD MMM YYYY HH:mm')}</div>
                      </Col>
                      <Col className="actor-action-bar col-right">
                        <div>
                          <div className="space-between">
                            <span className="hide-btn font-header" onClick={e => this.onRemoveProject(project.id)}><i className="icon icon-eye"></i> ซ่อน</span>
                            <span className="remove-btn font-header" onClick={e => this.onRemoveProject(project.id)}><i className="icon icon-trash"></i> ลบ</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                }) : (
                  <div className="no-project-panel">
                    <div><strong>{actor.name}</strong> ยังไม่เคยบันทึกข้อมูลงานไว้ เริ่มสร้างข้อมูลงานของ <strong>{actor.name}</strong> โดยกดปุ่ม "สร้างงานใหม่"</div>
                    <Button className="new-project-btn font-header" onClick={() => this.setModal(true)}>สร้างเลย!</Button>
                  </div>

                )}
              </div>

              
            </Container>
            <Modal show={show} onHide={() => this.setModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title className="font-header">สร้างงานใหม่</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProjectNewForm onSubmit={data => this.onCreateProject(data)} />
              </Modal.Body>
            </Modal>
        </Fragment>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  findActorProjects,
  createProject,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActorListPage);