import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import { reduxForm, Fields, formValueSelector } from 'redux-form';
import { Button } from 'react-bootstrap';

import DateRangePickerField from '@components/Field/DateRangePickerField';

class EditableFlexTimeRangeForm extends PureComponent {
  state = {
    mode: 'view',
  }

  onSubmit = (data) => {
    const { onSubmit } = this.props;
    console.log('onSubmit: ', data);
    if (onSubmit) onSubmit(data);
    this.setMode('view');
  }

  setMode = (mode) => {
    this.setState({ mode });
  }

  render() {
    const { data, handleSubmit } = this.props;
    const { mode } = this.state;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={classnames('editable', mode)}>
          { mode === 'edit'? (
            <div className="form-inline">
              <Fields
                names={['startDate', 'endDate']}
                component={DateRangePickerField}
                startDateId="startDate"
                endDateId="endDate"
                focusedInput
                isOutsideRange={this.handleOutsideRange}
                onClose={this.onClose}
                />
              <Button type="submit">Save</Button>
            </div>
          ) : (
            <div>
              {data && (
                <span>{moment(data.startDate).format('YYYY-MM-DD')} - {moment(data.endDate).format('YYYY-MM-DD')}</span>
              )}
              <div className="btn btn-primary btn-sm" onClick={() => this.setMode('edit')}>Edit</div>
            </div>
          )}
        </div>
      </form>
    );
  }
}
const formName = 'editable-flex-time-range-form';
const selector = formValueSelector(formName);
const mapStateToProps = state => ({
  data: selector(state, 'name', 'startDate', 'endDate'),
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({ form: formName }),
)(EditableFlexTimeRangeForm);
