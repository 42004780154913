import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Container } from 'react-bootstrap';
import ActorForm from '../components/ActorForm';
import { createActor } from '../reducer';

class ActorNewPage extends PureComponent {

  onSubmit(values) {
    const { createActor, history } = this.props;
    createActor({
      ...values,
      skills: values.skills && Object.keys(values.skills).reduce((result, skill) => ((values.skills[skill])? [ ...result, skill ]: result), []),
      issues: values.issues && Object.keys(values.issues).reduce((result, issue) => ((values.issues[issue])? [ ...result, issue ]: result), []),
      areas:  values.areas  && Object.keys(values.areas).reduce((result, area) => ((values.areas[area])? [ ...result, area ]: result), []),
    }).then(() => {
      history.push('/actors');
    });
  }

  render() {
    return (
      <div>
        <Container>
          <section className="page-header">
            <h1>New Actor</h1>
          </section>
          <section className="page-body">
            <ActorForm onSubmit={values => this.onSubmit(values)} />
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  createActor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorNewPage);