import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '@features/home/components/Layout';

import './ProfileLayout.scss';

class ProfileLayout extends PureComponent {
  render() {
    const { currentUser, children } = this.props;
    return (
      <Layout>
        <section>
        { currentUser && (
          <Container className="profile-layout">
            <Row>
              <Col md={3} className="profile-sidebar">
                <h4>{currentUser.email}</h4>
                
                <Nav defaultActiveKey="dashboard" className="flex-column">
                  <Nav.Link eventKey="actors"><Link to="/profile/actors">จัดการคน</Link></Nav.Link>
                  <Dropdown.Divider />
                  <Nav.Link eventKey="account-settings"><Link to="/profile/settings">แก้ไขข้อมูลส่วนตัว</Link></Nav.Link>
                  <Nav.Link eventKey="change-password"><Link to="/profile/change-password">เปลี่ยนรหัสผ่าน</Link></Nav.Link>
                  <Nav.Link eventKey="deactivate"><Link to="/profile/deactivate">ลบบัญชี</Link></Nav.Link>
                </Nav>
              </Col>
              <Col md={9}>
                {children}
              </Col>
            </Row>
          </Container>
        )}
        </section>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout);