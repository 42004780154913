import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '@features/home/components/Layout';
import { getProject, findProjectActors, findProjectImages } from '@features/projects/reducer';
import DraftView from '@components/DraftView';
import { Map, isValidCoordinate, ActorMarker, ProjectMarker, Popup, Layer, Feature } from '@components/MapBox';
import Lightbox from 'react-image-lightbox';
import ActorCard from '@features/actors/components/ActorCard';
import ProjectCard from '@features/projects/components/ProjectCard';
import moment from 'moment-with-locales-es6';
import './ProjectPage.scss';

import defaultThumbnail from '@assets/images/defaults/thumbnail.png';
import 'react-image-lightbox/style.css';

moment.locale('th');

class ProjectPageView extends PureComponent {
  state = {
    project: null,
    actors: [],
    actorViewMode: 'list',
    images: [],
    photoIndex: 0,
    showImageModal: false,
  }

  async componentDidMount() {
    const { getProject, findProjectActors, findProjectImages, id } = this.props;
    const project = await getProject(id);
    const actors = await findProjectActors(id);
    const images = await findProjectImages(id);
    this.setState({ project, actors, images });
  }

  setActorViewMode(actorViewMode) {
    this.setState({ actorViewMode });
  }

  onOpenImageModel(index) {
    this.setState({
      showImageModal: true,
      photoIndex: index,
    })
  }

  render() {
    const { project, actors, images, actorViewMode, showImageModal, photoIndex } = this.state;
    const { currentUser } = this.props;
    if (!project) return null;

    const editButton = <Link to={`/projects/${project.id}/${project.name}/edit`}><Button className="yt-btn">แก้ไข</Button></Link>
    const additionRightMenu = currentUser? [editButton]: [];

    return (
      <Layout className="project-page view" additionRightMenu={additionRightMenu}>
        {project && (
          <Fragment>
            <div className="cover-section">
              <div className="cover-wrapper">
                <div className="cover" style={{ backgroundImage: `url(${project.thumbnail || defaultThumbnail}`}}></div>
              </div>
              <div className="project-intro">
                <div className="project-header">
                  <h1 className="project-name">{project.name}</h1>
                </div>
                <div className="about">
                  <DraftView>{project.description}</DraftView>
                </div>
              </div>
            </div>

            {actors && actors.length > 0 && (
              <Container className="actor-list-section">
                <div className="section-header">
                  <h4 className="section-title">คนขับเคลื่อน</h4>
                  <ul className="nav">
                    {actorViewMode !== 'map' && <li onClick={() => this.setActorViewMode('map')}><i className="icon icon-earth"></i>ดูแผนที่</li>}
                    {actorViewMode !== 'list' && <li onClick={() => this.setActorViewMode('list')}><i className="icon icon-book"></i>ดูคนขับเคลื่อน</li>}
                  </ul>
                </div>

                {actorViewMode === 'map'? (
                  <div className="map-container">
                    <Map center={project.latlng.coordinates} onClick={() => this.setState({ selected: null })}>
                      {actors && actors.map(actor => (actor.latlng && isValidCoordinate(actor.latlng.coordinates) &&
                        <Fragment>
                          <Layer type="line" layout={{
                            'line-cap': 'round',
                            'line-join': 'round',
                          }} paint={{
                            'line-color': '#888',
                            'line-width': 2
                          }}>
                            <Feature coordinates={[actor.latlng.coordinates, project.latlng.coordinates]} />
                          </Layer>
                          <ActorMarker
                            coordinates={actor.latlng.coordinates}
                            actor={actor}
                            label={actor.name}
                            onMouseEnter={() => {
                              this.setState({
                                selected: {
                                  type: 'actor',
                                  object: actor,
                                  coordinates: actor.latlng.coordinates,
                                  content: <ActorCard actor={actor} />,
                                },
                              });
                            }}
                          />
                        </Fragment>
                      ))}

                      <ProjectMarker
                        coordinates={project.latlng.coordinates}
                        actor={project}
                        label={project.name}
                        onMouseEnter={() => {
                          this.setState({
                            selected: {
                              type: 'project',
                              object: project,
                              coordinates: project.latlng.coordinates,
                              content: <ProjectCard project={project} />,
                            },
                          });
                        }}
                      />
                    </Map>
                  </div>
                ) : (
                  <Row className="actor-list">
                    { actors.map(actor => (
                      <Col md={4}>
                        <ActorCard actor={actor} />
                      </Col>
                    ))}
                  </Row>
                )}
              </Container>
            )}


            <Container className="info-section">
              { (project.startDate || project.endDate) && (
                <Row className="info type">
                  <Col md={4}><h4 className="info-title">ระยะเวลา</h4></Col>
                  <Col md={8}><h4><span>{moment(project.startDate).format('DD MMM YYYY')} - {moment(project.endDate).format('DD MMM YYYY')}</span></h4></Col>
                </Row>
              )}

              { project.issues && project.issues.length > 0 && (
                <Row className="info issue-list">
                  <Col md={4}><h4 className="info-title">ประเภทงาน</h4></Col>
                  <Col md={8}>
                    { project.issues && project.issues.map(issue => (
                      <Row className="issue">
                        <Col className="icon-col"><i className="icon icon-rocket"></i></Col>
                        <Col>
                          <h4 className="issue-name">{issue.name}</h4>
                          <p className="issue-description">{issue.description}</p>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>

              )}
              { ( project.tel || project.email || project.lineId || project.facebook ) && (
              <Row className="info contacts">
                <Col md={4}><h4 className="info-title">ติดต่อ</h4></Col>
                <Col md={8}>
                  { project.tel && (
                    <Row className="contact">
                      <Col md={3}><label>โทรศัพท์</label></Col>
                      <Col><a className="contact-detail" href={`tel:${project.tel}`}>{project.tel}</a></Col>
                    </Row>
                  )}
                  { project.email && (
                    <Row className="contact">
                      <Col md={3}><label>E-mail</label></Col>
                      <Col><a className="contact-detail" href={`mailto:${project.email}`}>{project.email}</a></Col>
                    </Row>
                  )}
                  { project.lineId && (
                    <Row className="contact">
                      <Col md={3}><label>Line</label></Col>
                      <Col><span className="contact-detail">{project.lineId}</span></Col>
                    </Row>
                  )}
                  { project.facebook && (
                    <Row className="contact">
                      <Col md={3}><label>Facebook</label></Col>
                      <Col><a className="contact-detail" href={project.facebook} target="_blank">{project.facebook}</a></Col>
                    </Row>
                  )}
                </Col>
              </Row>
              )}
            </Container>

            {images && images.length > 0 && (
              <Container className="gallery-section">
                <div className="section-header">
                  <h4 className="section-title">รูปภาพ</h4>
                </div>
                <Row>
                  { images.map((image, i) => (
                    <Col md={6}>
                      <figure className="figure" onClick={() => this.onOpenImageModel(i)}>
                        <img className="figure-img" width="100%" src={image.url} alt={image.caption} />
                        <figcaption className="figure-caption">{image.caption}</figcaption>
                      </figure>
                    </Col>
                  ))}
                </Row>
                {showImageModal && (
                  <Lightbox
                    mainSrc={images[photoIndex].url}
                    nextSrc={images[(photoIndex + 1) % images.length].url}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
                    onCloseRequest={() => this.setState({ showImageModal: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )}

              </Container>
            )}
          </Fragment>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  findProjectActors,
  findProjectImages,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPageView);