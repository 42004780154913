import React, { PureComponent } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { InputField, CheckboxField, LatLngField, DraftField } from '@components/Field';

import { findTags } from '@features/tags/reducer';

class ArticleForm extends PureComponent {

  state = {
    issues: [],
    areas: [],
  };

  componentDidMount = async () => {
    const { findTags } = this.props;
    const issues = await findTags({ type: 'issue'});
    const areas = await findTags({ type: 'area'});
    this.setState({ issues, areas });
  }

  onSubmit(values) {
    const { handleSubmit } = this.props;
    handleSubmit(values);
  }

  render() {
    const { issues, areas } = this.state;

    return (
      <Form onSubmit={values => this.onSubmit(values)} className="actor-edit-form">

        <Field name="name" label="Name" component={InputField} type="text" required />
        <Field name="content" label="Content" component={DraftField} type="textarea" />
        <Field name="latlng" component={LatLngField} />

        <Row>
        <Col md={4}>
            <h4>Interest issues</h4>

            <div>
            {issues && issues.map(tag => (
              <Field key={tag.name} name={`issues[${tag.name}]`} label={tag.name} component={CheckboxField} />
            ))}
            </div>
          </Col>
          <Col md={4}>
            <h4>Focused areas</h4>

            <div>
            {areas && areas.map(tag => (
              <Field key={tag.name} name={`areas[${tag.name}]`} label={tag.name} component={CheckboxField} />
            ))}
            </div>
          </Col>
        </Row>

        <Button type="submit" variant="primary" block >Submit</Button>
      </Form>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  findTags,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'edit-article',
    
  }),
)(ArticleForm);