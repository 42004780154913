import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Layout from '@features/home/components/Layout';
import { Map, isValidCoordinate, ActorMarker, ProjectMarker, Popup, Layer, Feature } from '@components/MapBox';
import ActorFilterForm from '@features/actors/components/ActorFilterForm';
import ActorCard from '@features/actors/components/ActorCard';
import ProjectCard from '@features/projects/components/ProjectCard';
import FeedSlider from '@components/FeedSlider';
import { findActors, findActorProjects, ActorTypes } from '@features/actors/reducer';
import { findProjects, findProjectActors, issues as ProjectIssues } from '@features/projects/reducer';
import { mapbox } from '@config/api';
import './HomePage.scss';

class Homepage extends PureComponent {
  state = {
    feeds: [],
    actors: [],
    projects: [],
    links: [],
    initialFilters: {
      types: Object.keys(ActorTypes).reduce((result, key) => ({...result, [key]: true }), { all: true }),
      issues: ProjectIssues.reduce((result, issue) => ({...result, [issue.text]: true }), { all: true }),
    },
    center: null,
    selected: null,
  }

  async componentDidMount() {
    const { findActors, findProjects, findActorProjects, findProjectActors } = this.props;

    const feeds = await findActors();

    let actors = await findActors().then(async actors => await Promise.all(actors.map(async actor => {
      actor.projects = await findActorProjects(actor.id);
      return actor;
    })));
    let projects = await findProjects().then(async projects => await Promise.all(projects.map(async project => {
      project.actors = await findProjectActors(project.id);
      return project;
    })));

    this.setState({
      feeds,
      actors,
      projects,
    });
  }

  onFilterSubmit = async filter => {
    const { findActors, findProjects } = this.props;
    const actors = await findActors({ ...filter });
    const projects = await findProjects({ ...filter });
    this.setState({ actors, projects });
  }

  getSelectedMarker() {
    const { selected } = this.state;
    if (!selected) return null;

    if (selected.type === 'actor' && selected.object) {
      const { object: actor } = selected;
      return (
        <Link to={`/actors/${actor.id}/${actor.name}`}>
          <ActorMarker
            key={actor.id}
            coordinates={actor.latlng.coordinates}
            actor={actor}
            label={actor.name}
            onMouseEnter={() => {
              this.setState({
                // center: actor.latlng.coordinates,
                selected: {
                  type: 'actor',
                  object: actor,
                  coordinates: actor.latlng.coordinates,
                  content: <ActorCard actor={actor} />,
                },
              });
            }}
            onMouseLeave={() => this.setState({ selected: null })}
          />
        </Link>
      )
    } else if (selected.type === 'project' && selected.object) {
      const { object: project } = selected;
      return (
        <Link to={`/projects/${project.id}/${project.name}`}>
          <ProjectMarker
            key={project.id}
            coordinates={project.latlng.coordinates}
            label={project.name}
            onMouseEnter={() => {
              this.setState({
                // center: project.latlng.coordinates,
                selected: {
                  type: 'project',
                  object: project,
                  coordinates: project.latlng.coordinates,
                  content: <ProjectCard project={project} />,
                },
              });
            }}
            onMouseLeave={() => this.setState({ selected: null })}
          />
        </Link>
      )      
    }
  }

  getSelectedLinks() {
    const { selected } = this.state;
    if (!selected) return null;
    let links = [];
    if (selected.type === 'actor') {
      const { object: actor } = selected;
      if (actor.projects) {
        links = actor.projects.map(project => {
          if (project.latlng && isValidCoordinate(project.latlng.coordinates)) {
            return (
            <Layer type="line" layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }} paint={{
              'line-color': '#888',
              'line-width': 2
            }}>
              <Feature coordinates={[actor.latlng.coordinates, project.latlng.coordinates]} />
            </Layer>)
          }
          return null;
        }).filter(link => !!link)
      }
    }
    if (selected.type === 'project') {
      const { object: project } = selected;
      if (project.actors) {
        links = project.actors.map(actor => {
          if (actor.latlng && isValidCoordinate(actor.latlng.coordinates)) {
            return (
            <Layer type="line" layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }} paint={{
              'line-color': '#888',
              'line-width': 2
            }}>
              <Feature coordinates={[project.latlng.coordinates, actor.latlng.coordinates]} />
            </Layer>)
          }
          return null;
        }).filter(link => !!link)
      }
    }
    return links;
  }

  render() {
    const { feeds, actors, projects, selected, center, initialFilters } = this.state;
    const selectedMarker = this.getSelectedMarker();
    const links = this.getSelectedLinks();

    return (
      <Layout className="homepage" hideFooter={true}>
        <div className="main-container">
            <div className="sidebar">
              <h2><i className="icon icon-book"></i> ข้อมูล</h2>
              <h4 className="actor-list-title">อัพเดตล่าสุด</h4>
              <FeedSlider feeds={feeds} />

              <h2><i className="icon icon-earth"></i> แผนที่</h2>
              <ActorFilterForm initialValues={initialFilters} onSubmit={this.onFilterSubmit} />
            </div>
            <div className="map-container">
              <Map center={center || mapbox.defaultCoordinate} onClick={() => this.setState({ selected: null })}>
                {actors && actors.map(actor => (actor.latlng && isValidCoordinate(actor.latlng.coordinates) &&
                  <ActorMarker
                    key={actor.id}
                    coordinates={actor.latlng.coordinates}
                    actor={actor}
                    label={actor.name}
                    onMouseEnter={() => {
                      this.setState({
                        // center: actor.latlng.coordinates,
                        selected: {
                          type: 'actor',
                          object: actor,
                          coordinates: actor.latlng.coordinates,
                          content: <ActorCard actor={actor} />,
                        },
                      });
                    }}
                  />
                ))}
                {projects && projects.map(project => (project.latlng && isValidCoordinate(project.latlng.coordinates) &&
                  <ProjectMarker
                    key={project.id}
                    coordinates={project.latlng.coordinates}
                    label={project.name}
                    onMouseEnter={() => {
                      this.setState({
                        // center: project.latlng.coordinates,
                        selected: {
                          type: 'project',
                          object: project,
                          coordinates: project.latlng.coordinates,
                          content: <ProjectCard project={project} />,
                        },
                      });
                    }}
                  />
                ))}

                {selected && (
                  <Fragment>
                    {links}
                    {selectedMarker}
                    <Popup
                      key={selected.id}
                      offset={{
                        'bottom': [0, -10],
                      }}
                      coordinates={selected.coordinates}
                      anchor={'bottom'}
                      >
                      {selected.content}
                    </Popup>
                  </Fragment>
                )}
              </Map>
            </div>
          </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  findActors,
  findProjects,
  findActorProjects,
  findProjectActors,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);