import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col, Button } from 'react-bootstrap';

import ProjectSettingsLayout from '../containers/ProjectSettingsLayout';
import ProjectForm from '../components/ProjectForm';
import { getProject, updateProject } from '../reducer';

class ProjectSettingsAboutPage extends PureComponent {

  state = {
    project: null,
  }

  async init() {
    const { id, getProject } = this.props;
    const project = await getProject(id);
    this.setState({ project });
  }

  componentDidMount() {
    this.init();
  }

  onSubmit(data) {
    const { id, updateProject } = this.props;
    data = {
      ...data,
      issues: data.issues && Object.keys(data.issues).reduce((result, issue) => ((data.issues[issue])? [ ...result, issue ]: result), []),
      areas: data.areas && Object.keys(data.areas).reduce((result, area) => ((data.areas[area])? [ ...result, area ]: result), []),
    };
    updateProject(id, data);
  }

  render() {
    const { project } = this.state;
    return (
      <Fragment>
        {project && (
          <ProjectSettingsLayout project={project}>
            <h4>Project Edit</h4>
            <ProjectForm
              onSubmit={data => this.onSubmit(data)}
              initialValues={{
                ...project,
                issues: project.issues && project.issues.reduce((result, issue) => ({ ...result, [issue]: true }), {}),
                areas: project.areas && project.areas.reduce((result, area) => ({ ...result, [area]: true }), {}),
              }}
              />
          </ProjectSettingsLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  updateProject,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsAboutPage);