import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { LatLngField } from '@components/Field';

class EditableLatLngForm extends PureComponent {
  onChange = e => {
    const { handleSubmit } = this.props;
    setTimeout(() => handleSubmit(), 200);
  }

  render() {
    const { name, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field name={name} component={LatLngField} onChange={e => this.onChange(e)} />
      </form>
    );
  }
}

export default reduxForm()(EditableLatLngForm);
