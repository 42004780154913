
import React, { PureComponent } from 'react';
import { mapbox } from '@config/api';
import ReactMapboxGl, { ZoomControl, ScaleControl, RotationControl } from 'react-mapbox-gl';

const { token, defaultCoordinate } = mapbox;

const Mapbox = ReactMapboxGl({
  accessToken: token,
  doubleClickZoom: false,
  attributionControl: false,
});

class Map extends PureComponent {
  render() {
    const { children, ...props } = this.props;
    return (
      <Mapbox
        // style="mapbox://styles/poprvr/ck3rjgxth0ftz1cqj92cp64vn"
        // style="mapbox://styles/mapbox/streets-v9"
        style="mapbox://styles/poprvr/ck915nqtb0vtn1imjigx3g6be"
        containerStyle={{ height: '100%', width: '100%' }}
        center={defaultCoordinate}
        {...props}
      >
        <ZoomControl />
        <RotationControl />
        
        {children}
      </Mapbox>
    );
  }
}

export default Map;