import React, { Component } from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import { RegisterPage, LoginPage } from '@features/auth';
import { ProfileDashboardPage, ProfileActorListPage, ProfileProjectListPage, ProfileSettingsPage, ProfileChangePasswordPage, ProfileDeactivatePage } from '@features/home/profile';
import { HomePage, AboutPage, ActorListPage, ActorPage, ActorEditPage, ActorEditForm, ActorPageView, ProjectListPage, ProjectPage, ProjectEditPage, ProjectPageView, MapPage } from '@features/home';

class HomeRouter extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path={`/auth/register`} component={props => <RegisterPage {...props} />} />
        <Route exact path={`/auth/login`} component={props => <LoginPage {...props} />} />

        <Route exact path={`/profile/actors/:id/projects`} component={({ match, ...props }) => (<ProfileProjectListPage id={match.params.id} {...props} />)}></Route>
        <Route exact path={`/profile/actors`} component={props => <ProfileActorListPage {...props} />} />
        <Route exact path={`/profile/settings`} component={props => <ProfileSettingsPage {...props} />} />
        <Route exact path={`/profile/change-password`} component={props => <ProfileChangePasswordPage {...props} />} />
        <Route exact path={`/profile/deactivate`} component={props => <ProfileDeactivatePage {...props} />} />
        <Route exact path={`/profile`} component={props => <ProfileDashboardPage {...props} />} />

        <Route path={`/actors/:id/:name/edit`} component={({ match, ...props }) => (<ActorPage id={match.params.id} mode="edit" {...props} />)}></Route>
        <Route path={`/actors/:id/:name`} component={({ match, ...props }) => (<ActorPage id={match.params.id} mode="view" {...props} />)}></Route>
        <Route exact path={`/actors`} component={props => <ActorListPage {...props} />} />
        <Route path={`/projects/:id/:name/edit`} component={({ match, ...props }) => (<ProjectPage id={match.params.id} mode="edit" {...props} />)}></Route>
        <Route path={`/projects/:id/:name`} component={({ match, ...props }) => (<ProjectPage id={match.params.id} {...props} />)}></Route>
        <Route exact path={`/projects`} component={props => <ProjectListPage {...props} />} />
        <Route exact path={`/map`} component={props => <MapPage {...props} />} />
        <Route exact path={`/about`} component={props => <AboutPage {...props} />} />
        <Route exact path={``} component={props => <HomePage {...props} />} />
      </Switch>
    );
  }
}

export default HomeRouter;