import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';

import ActorProfileLayout from '../containers/ActorProfileLayout';
import { ProjectNewForm } from '@features/projects';
import { getActor, findActorProjects } from '../reducer';
import { createProject, removeProjectActor } from '@features/projects/reducer';

class ActorProfileProjectListPage extends PureComponent {

  state = {
    actor: null,
    projects: null,
  }

  async init() {
    const { id, getActor, findActorProjects } = this.props;
    const actor = await getActor(id);
    const projects = await findActorProjects(id);
    this.setState({ actor, projects })
  }

  componentDidMount() {
    this.init();
  }

  onCreateProject(data) {
    const { id, createProject } = this.props;
    createProject({
      ...data,
      actorId: id, 
    }).then(project => {
      this.init();
    })
  }

  onRemoveProject(projectId) {
    const { id, removeProjectActor } = this.props;
    removeProjectActor(projectId, id).then(() => this.init());
  }

  render() {
    const { actor, projects } = this.state;
    return (
      <Fragment>
        {actor && (
          <ActorProfileLayout actor={actor}>
            <h3>Projects</h3>

            {projects && projects.map(project => (
              <Row>
                <Col><Link to={`/projects/${project.id}`}>{project.name}</Link></Col>
                <Col><Button variant="danger" onClick={e => this.onRemoveProject(project.id)}>Remove</Button></Col>
              </Row>
            ))}

            <ProjectNewForm onSubmit={data => this.onCreateProject(data)} />

          </ActorProfileLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
  findActorProjects,
  createProject,
  removeProjectActor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorProfileProjectListPage);