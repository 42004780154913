import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';

class UserEditForm extends PureComponent {

  onSubmit(values) {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit(values);
  }

  render() {
    return (
      <Form onSubmit={values => this.onSubmit(values)} className="user-edit-form">
        <Form.Group>
          <Form.Label>Firstname</Form.Label>
          <Field
            name="firstname"
            className="form-control"
            component="input"
            type="text"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Lastname</Form.Label>
          <Field
            name="lastname"
            className="form-control"
            component="input"
            type="text"
          />
        </Form.Group>
        
        <Form.Group>
          <Form.Label>E-mail</Form.Label>
          <Field
            name="email"
            className="form-control"
            component="input"
            type="email"
          />
        </Form.Group>

        <Button type="submit" variant="primary" block >Submit</Button>
      </Form>
    )
  }
}
export default compose(
  reduxForm({
    form: 'edit-user',
  }),
)(UserEditForm);