import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActorProfileLayout from '../containers/ActorProfileLayout';
import ActorForm from '../components/ActorForm';
import { getActor } from '../reducer';

class ActorProfileAboutPage extends PureComponent {

  state = {
    actor: null,
  }

  async init() {
    const { id, getActor } = this.props;
    this.setState({ actor: null });
    const actor = await getActor(id);
    this.setState({ actor })
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id) {
      this.init();
    }
  }

  onSubmit(data) {
    console.log(data);
  }

  render() {
    const { actor } = this.state;
    return (
      <Fragment>
        {actor && (
          <ActorProfileLayout actor={actor}>
            <h3>About</h3>
            <ActorForm initialValues={{
              ...actor,
              skills: actor.skills && actor.skills.reduce((result, skill) => ({ ...result, [skill]: true }), {}),
              issues: actor.issues && actor.issues.reduce((result, issue) => ({ ...result, [issue]: true }), {}),
              areas:  actor.areas  && actor.areas.reduce((result, area) => ({ ...result, [area]: true }), {}),
            }} onSubmit={values => this.onSubmit(values)} />

          </ActorProfileLayout>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({
  getActor,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorProfileAboutPage);