import React from 'react';
import moment from 'moment-with-locales-es6';
import classnames from 'classnames';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ActorTypes } from '@features/actors/reducer';
import './ActorCard.scss';

import defaultThumbnail from '@assets/images/defaults/thumbnail.png';

moment.locale('th');

export default ({ actor }) => (
  <Card className="actor actor-card">
    <Link to={`/actors/${actor.id}/${actor.name}`}>
      <div className="card-thumbnail" style={{ backgroundImage: `url(${actor.thumbnail || defaultThumbnail}` }} />
      <Card.Body>
        <h5 className="name" style={{ color: ActorTypes[actor.type] && ActorTypes[actor.type].color }}>{actor.name}</h5>
        <div className="type">{ActorTypes[actor.type] && ActorTypes[actor.type].name}</div>
        <div className="issues">
          { actor.issues && actor.issues.slice(0, 4).map(issue => (
            <span className="badge badge-dark" key={issue.name}>
              <i className={classnames('icon', issue.icon || 'icon-triangle')}></i>&nbsp;{issue.name}
            </span>
          ))}

          { actor.issues && actor.issues.length > 4 && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={props => (
                <Tooltip className="issue-tooltip" {...props}>
                  {actor.issues.slice(4).map(issue => (
                    <div><i className={classnames('icon', issue.icon || 'icon-triangle')}></i>&nbsp;{issue.name}</div>
                  ))}
                </Tooltip>
              )}
            >
              <span className="badge badge-dark">+{ actor.issues.length - 4 }</span>
            </OverlayTrigger>
          )}
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="last-update">อัพเดตเมื่อ {moment(actor.updatedAt).format('LL')}</div>
      </Card.Footer>
    </Link>
  </Card>

);