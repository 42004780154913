import * as base from './_base';
import { endpoint } from '@config/api';

export default {
  ...base.generateCRUD('actors'),

  count: () => {
    const url = `${endpoint}/actors/count`;
    return base.get(url);
  },

  findProjects: (actorId, query, options) => {
    const url = `${endpoint}/actors/${actorId}/projects`;
    return base.get(url, query, options);
  },
  
  findMembers: (actorId, query, options) => {
    const url = `${endpoint}/actors/${actorId}/members`;
    return base.get(url, query, options);
  },
  addMember: (actorId, data, options) => {
    const url = `${endpoint}/actors/${actorId}/members`;
    return base.post(url, data, options);    
  },
  removeMember: (actorId, userId, options) => {
    const url = `${endpoint}/actors/${actorId}/members/${userId}`;
    return base.del(url, options);
  },

  findImages: (actorId, query, options) => {
    const url = `${endpoint}/actors/${actorId}/images`;
    return base.get(url, query, options);
  },

  addImages: (actorId, data, options) => {
    const url = `${endpoint}/actors/${actorId}/images`;
    return base.post(url, data, options);
  },

  removeImage: (actorId, imageId, options) => {
    const url = `${endpoint}/actors/${actorId}/images/${imageId}`;
    return base.del(url, options);
  },

  findArticles: (actorId, query, options) => {
    const url = `${endpoint}/actors/${actorId}/articles`;
    return base.get(url, query, options);
  },

}