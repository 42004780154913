import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import moment from 'moment-with-locales-es6';

import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './FeedSlider.scss';

moment.locale('th');

class FeedSlider extends PureComponent {
  render() {
    const { feeds } = this.props;
    const settings = {
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    return (
      <div key={feeds}>
        { feeds && (
          <Slider {...settings} className="feed-slick">
            { feeds.map(feed => (
              <div className="actor">
                <Link to={`/actors/${feed.id}/${feed.name}`}><h4 className="name">{feed.name}</h4></Link>
                <div className="last-update">{moment(feed.updatedAt).fromNow()}</div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    );
  }
}

export default FeedSlider;