import React, { PureComponent } from 'react';

import ProfileLayout from '../containers/ProfileLayout';
import UserEditPasswordForm from '@features/users/components/UserEditPasswordForm';

class ProfileChangePasswordPage extends PureComponent {
  render() {
    return (
      <ProfileLayout>
        <h3>Change Password</h3>
        <UserEditPasswordForm />

      </ProfileLayout>
    )
  }
}

export default ProfileChangePasswordPage;