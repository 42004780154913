import api from '@app/api';

export const AUTH_REGISTER_REQUEST  = 'action.auth.register.request';
export const AUTH_REGISTER_SUCCESS  = 'action.auth.register.success';
export const AUTH_REGISTER_ERROR    = 'action.auth.register.error';

export const AUTH_LOGIN_REQUEST     = 'action.auth.login.request';
export const AUTH_LOGIN_SUCCESS     = 'action.auth.login.success';
export const AUTH_LOGIN_ERROR       = 'action.auth.login.error';

export const AUTH_LOGOUT_REQUEST     = 'action.auth.logout.request';
export const AUTH_LOGOUT_SUCCESS     = 'action.auth.logout.success';
export const AUTH_LOGOUT_ERROR       = 'action.auth.logout.error';

export const AUTH_LOAD_CURRENT_USER  = 'action.auth.load-current-load';

export const ACCOUNT_RELOAD_REQUEST  = 'action.account.reload.request';
export const ACCOUNT_RELOAD_SUCCESS  = 'action.account.reload.success';
export const ACCOUNT_RELOAD_ERROR    = 'action.account.reload.error';

const initialState = {
  currentUser: null,
  currentActor: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOAD_CURRENT_USER:
      return { ...state, currentUser: action.user };
    default:
  }
  return state;
}

export const register = (data, options = {}) => dispatch => {
  dispatch({ type: AUTH_REGISTER_REQUEST, data });

  return api.auth.register(data, options).then(res => {
    const { user } = res.data;
    dispatch({ type: AUTH_REGISTER_SUCCESS, data });
    return user;
  }).catch(error => dispatch({ type: AUTH_REGISTER_ERROR, error, data }));
}

export const login = (data, options = {}) => dispatch => {
  dispatch({ type: AUTH_LOGIN_REQUEST, data });

  return api.auth.login(data, options).then(res => {
    const { user } = res.data;

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('accessToken', user.accessToken);

    dispatch({ type: AUTH_LOGIN_SUCCESS, user });

    dispatch(loadCurrentUser());

    return user;
  }).catch(error => {
    dispatch({ type: AUTH_LOGIN_ERROR, error, data });
    throw error;
  });
}

export const logout = (data, options = {}) => dispatch => {
  dispatch({ type: AUTH_LOGOUT_REQUEST, data });

  return api.auth.logout(data, options).then(res => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');

    dispatch({ type: AUTH_LOGOUT_SUCCESS });
    return true;
  }).catch(error => dispatch({ type: AUTH_LOGOUT_ERROR, error, data }));
}

export const loadCurrentUser = () => dispatch => {
  let user = localStorage.getItem('user');
  
  if (user) {
    user = JSON.parse(user);
    dispatch({ type: AUTH_LOAD_CURRENT_USER, user });
  }
  return user;
}

export const reloadAccount = () => dispatch => {
  dispatch({ type: ACCOUNT_RELOAD_REQUEST });
  console.log('actions.reloadAccount');
  return api.account.get().then(res => {
    const { user } = res.data;

    localStorage.setItem('user', JSON.stringify(user));

    dispatch({ type: ACCOUNT_RELOAD_SUCCESS, user });
    dispatch(loadCurrentUser());
  }).catch(error => {
    dispatch({ type: ACCOUNT_RELOAD_ERROR, error });
    throw error;
  })
}
