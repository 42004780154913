import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';
import classnames from 'classnames';

class ActorNewForm extends PureComponent {

  onSubmit(values) {
    const { handleSubmit } = this.props;
    if (handleSubmit) handleSubmit(values);
  }

  render() {
    const { className } = this.props;
    return (
      <Form onSubmit={values => this.onSubmit(values)} className={classnames(className, 'actor-new-form')}>
        <Form.Group>
          <Form.Label>ชื่อ</Form.Label>
          <Field
            name="name"
            className="form-control"
            component="input"
            type="text"
          />
        </Form.Group>

        <Button className="font-header" type="submit" block style={{ backgroundColor: '#29A7DE' }}>สร้าง</Button>
      </Form>
    )
  }
}
export default compose(
  reduxForm({
    form: 'new-actor',
  }),
)(ActorNewForm);