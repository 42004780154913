import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProjectPageView from '@features/home/projects/ProjectPageView';
import ProjectEditForm from '@features/home/projects/ProjectEditForm';

import { getProject, updateProject } from '@features/projects/reducer';

import './ProjectPage.scss';


class ProjectPage extends PureComponent {
  state = {
    project: null,
  }

  async componentDidMount() {
    const { getProject, id } = this.props;
    const project = await getProject(id);

    this.setState({ project });
  }

  onUpdateProject = async data => {
    const { project } = this.state;
    const { updateProject, history } = this.props;
    data = {
      ...data,
      fields: ['name', 'description', 'startDate', 'endDate', 'thumbnail', 'latlng', 'issues', 'tel', 'email', 'lineId', 'facebook'],
    }
    await updateProject(project.id, data, { mode: 'partial' }).then(() => {
      this.setState({
        project: {
          ...project,
          ...data,
        }
      });
    });
    history.push(`/projects/${project.id}/${project.name}`);
  }

  render() {
    const { currentUser, id, mode = 'view' } = this.props;
    const { project } = this.state
    return (
      <Fragment>
        { currentUser && mode === 'edit'? project && (
          <ProjectEditForm id={id} initialValues={{ ...project }} onSubmit={this.onUpdateProject} />
         ) : (
          <ProjectPageView id={id} />
        )}
      </Fragment>
    );

  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProject,
  updateProject,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);