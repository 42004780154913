import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { Button } from 'react-bootstrap';

import TagsFieldArray from '@components/Field/TagsFieldArray';

import './EditableTagsForm.scss';

class EditableTagsForm extends PureComponent {
  state = {
    mode: 'view',
  }

  onSubmit = (data) => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit(data);
    this.setMode('view');
  }

  setMode = (mode) => {
    this.setState({ mode });
  }

  render() {
    const { name, tags, allTags, placeholder, handleSubmit } = this.props;
    const { mode } = this.state;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className="editable-tags-form">
        <div className={classnames('editable', mode)}>
          { mode === 'edit'? (
            <div className="edit">
              <FieldArray name={name} component={TagsFieldArray} allTags={allTags} />
              
              <div className="form-footer">
                <Button type="submit">Save</Button>
              </div>
              
            </div>
          ) : (
            <div className="view">
              { tags && tags.map(tag => (
                <div className="tag" key={tag.name}>
                  <h4>{tag.name}</h4>
                  <div>{tag.description}</div>
                </div>
              ))}
 
              <div className="btn btn-primary btn-sm" onClick={() => this.setMode('edit')}>Edit</div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default reduxForm()(EditableTagsForm);
