import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './ActorProfileLayout.scss';

class ActorProfileLayout extends PureComponent {
  render() {
    const { actor, children } = this.props;
    console.log(actor);
    return (
      <section>
      { actor && (
        <Container className="actor-profile-layout">
          <Row>
            <Col md={3} className="actor-profile-sidebar">
              <h4>{actor.name}</h4>
              
              <Nav defaultActiveKey="dashboard" className="flex-column">
                <Link className="nav-link" to={`/actors/${actor.id}/about`}>About</Link>
                <Link className="nav-link" to={`/actors/${actor.id}/members`}>Members</Link>
                <Link className="nav-link" to={`/actors/${actor.id}/projects`}>Projects</Link>
                <Link className="nav-link" to={`/actors/${actor.id}/articles`}>Articles</Link>
                {/* <Link className="nav-link" to={`/actors/${actor.id}/needs`}>Needs</Link> */}
                <Dropdown.Divider />
                
                <Link className="nav-link" to={`/actors/${actor.id}/deactivate`}>Deactivate</Link>
              </Nav>
            </Col>
            <Col md={9}>
              {children}
            </Col>
          </Row>
        </Container>
      )}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActorProfileLayout);