import React from 'react';
import classnames from 'classnames';
import { colors } from '@config/constants';
import { Marker } from '@components/MapBox';
import './ProjectMarker.scss';

const ProjectMarker = ({ label, icon = 'icon-ball', className, ...props }) => (
  <Marker
    className={classnames('project-marker', className)}
    style={{ color: colors.purple }}
    offset={{
      'bottom': [0, 50],
    }}
    {...props}>
    <i className={classnames('icon', icon)}></i>
    {label && (<label>{label}</label>)}
  </Marker>
);

export default ProjectMarker;