import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Layout from '@features/home/components/Layout';
import ProjectCard from '@features/projects/components/ProjectCard';
import ProjectFilterForm from '@features/projects/components/ProjectFilterForm';
import { findProjects, issues as ProjectIssues } from '@features/projects/reducer';

import mockupThumbnail from '@assets/images/contents/mockup.jpg';

import './ProjectListPage.scss'

class ProjectListPage extends PureComponent {
  state = {
    projects: [],
    initialFilters: {
      issues: ProjectIssues.reduce((result, issue) => ({...result, [issue.text]: true }), { all: true }),  
    },

  }

  async componentDidMount() {
    const { findProjects } = this.props;
    const projects = await findProjects();
    this.setState({ projects });
  }

  onFilterSubmit = async filter => {
    const { findProjects } = this.props;
    this.setState({ projects: [] });
    const projects = await findProjects({ ...filter });
    if (projects) this.setState({ projects });
  }

  render() {
    const { projects, initialFilters } = this.state;
    return (
      <Layout className="project-list-page">
        <Container>
          <h1 className="page-title">รวมงาน</h1>
          <Row>
            <Col md={4}>
              <div>พื้นที่รวบรวมผลงานของเหล่า active citizen ที่สนใจและขับเคลื่อนการพัฒนาชุมชนย่านฝั่งธนบุรี</div>
              <ProjectFilterForm
                initialValues={initialFilters}
                onSubmit={this.onFilterSubmit}
                />
            </Col>
            <Col md={8}>
              <Row className="project-list">
                { projects && projects.map(project => (
                  <Col md={6}>
                    <ProjectCard project={project} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  findProjects,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListPage);