import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import auth from '@features/auth/reducer';
import actor from '@features/actors/reducer';
import tag from '@features/tags/reducer';
import project from '@features/projects/reducer';
import article from '@features/articles/reducer';
import need from '@features/needs/reducer';
import { reducer as formReducer } from 'redux-form';

const reducers = combineReducers({
  auth,
  actor,
  tag,
  project,
  article,
  need,
  form: formReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;