import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';

import TagForm from '../components/TagForm';

import { findTags, createTag, deleteTag } from '../reducer';

class TagEditPage extends PureComponent {
  state = {
    skills: [],
    issues: [],
    areas: [],
  }

  async componentDidMount() {
    this.init();
  }

  async init() {
    const { findTags } = this.props;
    const skills = await findTags({ type: 'skill' });
    const issues = await findTags({ type: 'issue' });
    const areas = await findTags({ type: 'area' });
    this.setState({ skills, issues, areas });

  }

  onDeleteTag(id) {
    const { deleteTag, findTags } = this.props;
    deleteTag(id).then(() => {
      this.init();
    });
  }

  onSubmit(values, options) {
    const { createTag, findTags, history } = this.props;
    createTag({
      ...values,
      ...options,
    }).then(() => {
      this.init();
    });
  }

  render() {
    const { skills, issues, areas } = this.state;

    return (
      <div>
        <Container>
          <section className="page-header">
            <h1 className="page-title">Tag</h1>
            <ButtonToolbar className="justify-content-between">
              <ButtonGroup>
              </ButtonGroup>
              <ButtonGroup>
              </ButtonGroup>
            </ButtonToolbar>
          </section>
          <section className="page-body">
            <Row>
            <Col>
                <h4>Skills &amp; Resources</h4>
                {skills && skills.map(tag => (
                  <Row key={tag.id}>
                    <Col>{tag.name}</Col>
                    <Col>
                      <ButtonGroup>
                        <Button variant="danger" onClick={e => this.onDeleteTag(tag.id)}>Delete</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                ))}
                <TagForm onSubmit={values => this.onSubmit(values, { type: 'skill' })} form="new-skill-tag" />
              </Col>
              <Col>
                <h4>Issues</h4>
                {issues && issues.map(tag => (
                  <Row key={tag.id}>
                    <Col>{tag.name}</Col>
                    <Col>
                      <ButtonGroup>
                        <Button variant="danger" onClick={e => this.onDeleteTag(tag.id)}>Delete</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                ))}
                <TagForm onSubmit={values => this.onSubmit(values, { type: 'issue' })} form="new-issue-tag" />
              </Col>
              <Col>
                <h4>Areas</h4>
                {areas && areas.map(tag => (
                  <Row key={tag.id}>
                    <Col>{tag.name}</Col>
                    <Col>
                      <ButtonGroup>
                        <Button variant="danger" onClick={e => this.onDeleteTag(tag.id)}>Delete</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                ))}
                <TagForm onSubmit={values => this.onSubmit(values, { type: 'area' })} form="new-area-tag" />
              </Col>
            </Row>
            
          </section>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.tag.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  findTags,
  createTag,
  deleteTag,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagEditPage);