import React from 'react';
import moment from 'moment-with-locales-es6';
import classnames from 'classnames';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ProjectCard.scss';

import defaultThumbnail from '@assets/images/defaults/thumbnail.png';

moment.locale('th');

export default ({ project }) => (
  <Card className="project project-card">
    <Link to={`/projects/${project.id}/${project.name}`}>
      <div className="card-thumbnail" style={{ backgroundImage: `url(${project.thumbnail || defaultThumbnail}` }} />
      <Card.Body>
        <h5 className="name">{project.name}</h5>
        <div className="issues">
          { project.issues && project.issues.slice(0, 4).map(issue => (
            <span className="badge badge-dark" key={issue.name}>
              <i className={classnames('icon', issue.icon || 'icon-triangle')}></i>&nbsp;{issue.name}
            </span>
          ))}

          { project.issues && project.issues.length > 4 && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={props => (
                <Tooltip className="issue-tooltip" {...props}>
                  {project.issues.slice(4).map(issue => (
                    <div><i className={classnames('icon', issue.icon || 'icon-triangle')}></i>&nbsp;{issue.name}</div>
                  ))}
                </Tooltip>
              )}
            >
              <span className="badge badge-dark">+{ project.issues.length - 4 }</span>
            </OverlayTrigger>
          )}
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="last-update">อัพเดตเมื่อ {moment(project.updatedAt).format('LL')}</div>
      </Card.Footer>
    </Link>
  </Card>
);