import React, { PureComponent, Fragment } from 'react';
import Slider from 'react-slick';
import moment from 'moment-with-locales-es6';

import ProjectCard from '@features/projects/components/ProjectCard';

import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './ProjectSlider.scss';

moment.locale('th');

class FeedSlider extends PureComponent {
  render() {
    const { projects } = this.props;
    const settings = {
      center: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
    };

    return (
      <div key={projects}>
        { projects && (
          <Slider {...settings} className="project-slick">
            { projects.map(project => (
              <div className="project-slick-slide-wrapper">
                <ProjectCard key={project.id} project={project} />
              </div>
            ))}
          </Slider>
        )}
      </div>
    );
  }
}

export default FeedSlider;