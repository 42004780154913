import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Layout from '@features/home/components/Layout';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import RegisterForm from '../components/RegisterForm';
import { register } from '../reducer';

import './RegisterPage.scss';

class RegisterPage extends PureComponent {

  async onSubmit(data) {
    const { register, history } = this.props;
    register(data).then(user => {
      history.push('/auth/login');
    });
  }

  render() {
    return (
      <Layout className="register-page" hideFooter={true}>
        <Container>
          <RegisterForm onSubmit={data => this.onSubmit(data)} />
          <div className="register-footer">
            เป็นสมาชิกคนธนแล้วใช่หรือไม่<br />
            เข้าใช้งานได้ <Link to="/auth/login">ที่นี่</Link>
          </div>

        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
  register,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);