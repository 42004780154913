import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Form, Button } from 'react-bootstrap';

class UserInviteForm extends PureComponent {

  onSubmit(data) {
    const { handleSubmit } = this.props;
    handleSubmit(data);
  }

  render() {
    return (
      <Form onSubmit={data => this.onSubmit(data)} className="form-inline user-invite-form">
        <Form.Group>
          <Form.Label>Add Member</Form.Label>
          <Field
            name="email"
            className="form-control"
            component="input"
            type="email"
            placeholder="E-mail"
          />
        </Form.Group>

        <Button type="submit" variant="primary" >Submit</Button>
      </Form>
    )
  }
}
export default compose(
  reduxForm({
    form: 'invite-user',
  }),
)(UserInviteForm);